import React from "react";
import style from "./PortfolioProjectButton.module.scss";
import { ReactComponent as ButtonArrow } from "../../../assets/ButtonArrow.svg";

const PortfolioProjectButton = ({ ...props }) => {
  return (
    <button type="button" {...props}>
      {props.text}
      <ButtonArrow />
    </button>
  );
};

export default PortfolioProjectButton;
