import React, { useEffect, useState } from "react";
import style from "./PortfolioProjects.module.scss";
import Dribble from "../../../assets/Dribble";
import Instagram from "../../../assets/Instagram";
import LinkedIn from "../../../assets/LinkedIn";

import { ReactComponent as Cross } from "../../../Icons/Cross.svg";
import { ReactComponent as ButtonArrow } from "../../../assets/RightArrow.svg";
import PortfolioProject1 from "../../../assets/PortfolioProjects1.png";
import PortfolioProject1IMG2 from "../../../assets/PostfolioProjects1IMG2.png";
import PortfolioProject2IMG1 from "../../../assets/PortfolioProject2IMG1.png";
import PortfolioProject2IMG2 from "../../../assets/PortfolioProject2IMG2.png";
import PortfolioProject2IMG3 from "../../../assets/PortfolioProjects2IMG3.png";
import PortfolioProject2IMG4 from "../../../assets/PortfolioProjects2IMG4.png";
import { ReactComponent as PortfolioProject4IMG1 } from "../../../assets/PortfolioProject4IMG1.svg";
import { ReactComponent as PortfolioProject4IMG2 } from "../../../assets/PorftfolioProjects4IMG2.svg";
import { ReactComponent as PortfolioProject3IMG1 } from "../../../assets/PortfolioProject3IMG1.svg";
import { ReactComponent as PorftfolioProjects3IMG2 } from "../../../assets/PortfolioProject3IMG2.svg";
import PorftfolioProjects3IMG3 from "../../../assets/PortfolioProjects3IMG3.png";
import PorftfolioProjects3IMG4 from "../../../assets/PortfolioProject3IMG4.png";
import PorftfolioProjects3IMG5 from "../../../assets/PortfolioProject3IMG5.png";
import PorftfolioProjects3IMG6 from "../../../assets/PortfolioProject3IMG6.png";
import PorftfolioProjects3IMG7 from "../../../assets/PortfolioProject3IMG7.png";
import PorftfolioProjects3IMG8 from "../../../assets/PortfolioProject3IMG8.png";
import PorftfolioProjects3IMG9 from "../../../assets/PortfolioProject3IMG9.png";
import PorftfolioProjects3IMG10 from "../../../assets/PortfolioProject3IMG10.png";
import PorftfolioProjects3IMG11 from "../../../assets/PortfolioProject3IMG11.png";

// import PortfolioProject2IMG4 from "../../../assets/PortfolioProject2IMG4.png";
import { ReactComponent as PortfolioProjectsFooterBackButtonArrow } from "../../../assets/PortfolioProjectsFooterBackButtonArrow.svg";
import { ReactComponent as PortfolioProjectArrow } from "../../../assets/PortfolioProjectArrow.svg";
import { useNavigate } from "react-router-dom";
import ContactUsButton from "../../UI/ContactUsButton/ContactUsButton";

const PortfolioProjects = ({
  isAppPortfolioProjects,
  scrollDirection,
  setScrollDirection,
  handleTouchStartMobailScrollDirection,
  handleTouchMoveMobailScrollDirection,
  isMobile,
  setIsMobileContentFromSolutio,
  setIsFromPortfolioProjectsToContactUs,
}) => {
  useEffect(() => {
    isAppPortfolioProjects(true);
    window.scrollTo(0, 0);
  }, []);

  const projects = {
    project1: (
      <div>
        <h2 className={style.portfolioProjectsTitle}>
          Financial SAAS platform
        </h2>
        <p className={style.portfolioProjectsText}>
          We embark on a journey to create cutting-edge financial platforms
          tailored to your unique needs. Our process is a carefully
          choreographed series of steps that blend creativity with technical
          expertise to bring your financial vision to life. From inception to
          deployment and beyond, we navigate this path with precision and
          innovation to ensure your financial platform stands as a beacon of
          success in the digital world.
        </p>
        <div>
          <img
            src={PortfolioProject1}
            alt=""
            className={style.portfolioProjectsImg}
          />
        </div>
        <div>
          <h3 className={style.portfolioProjectsSectionHeader}>
            Discovery and Analysis
          </h3>
          <ul className={style.portfolioProjectsSection}>
            <li className={style.portfolioProjectsSectionElement}>
              &#x2022; Initial Consultation: Begin with an in-depth discussion
              with the client to understand their financial platform
              requirements, business objectives, target audience, and any
              existing systems.
            </li>
            <li className={style.portfolioProjectsSectionElement}>
              &#x2022; Market Research: Conduct research to understand the
              competitive landscape and industry-specific trends within the
              financial sector.
            </li>
            <li className={style.portfolioProjectsSectionElement}>
              &#x2022; Collect detailed project requirements, including
              functional specifications, design preferences, technical
              constraints, and project timelines.
            </li>
          </ul>
        </div>
        <div>
          <h3 className={style.portfolioProjectsSectionHeader}>
            Planning and Strategy
          </h3>
          <ul className={style.portfolioProjectsSection}>
            <li className={style.portfolioProjectsSectionElement}>
              &#x2022; Develop a project plan that outlines the scope of work,
              milestones, and a timeline for design and development.
            </li>
            <li className={style.portfolioProjectsSectionElement}>
              &#x2022; Create a strategic roadmap for user experience (UX) and
              user interface (UI) design, development, and testing phases.
            </li>
          </ul>
        </div>
        <div>
          <img
            src={PortfolioProject1IMG2}
            alt=""
            className={style.portfolioProjectsImg}
          />
        </div>
        <div>
          <h3 className={style.portfolioProjectsSectionHeader}>Design Phase</h3>
          <ul className={style.portfolioProjectsSection}>
            <li className={style.portfolioProjectsSectionElement}>
              &#x2022; User Research: Conduct user research to gain insights
              into the target audience's needs, behaviors, and pain points.
            </li>
            <li className={style.portfolioProjectsSectionElement}>
              &#x2022; Information Architecture: Create a clear and intuitive
              information structure for the financial platform, defining
              navigation, content hierarchy, and user flows.
            </li>
            <li className={style.portfolioProjectsSectionElement}>
              &#x2022; Wireframing: Develop wireframes to visualize the
              platform's layout and functionality, ensuring a logical user
              journey.
            </li>
            <li className={style.portfolioProjectsSectionElement}>
              &#x2022; UI Design: Craft visually appealing and user-friendly
              interfaces, incorporating branding elements and design best
              practices.
            </li>
            <li className={style.portfolioProjectsSectionElement}>
              &#x2022; Prototyping: Create interactive prototypes to demonstrate
              user interactions and validate design concepts with stakeholders.
            </li>
            <li className={style.portfolioProjectsSectionElement}>
              &#x2022;Accessibility: Ensure that the design complies with
              accessibility standards to accommodate users with disabilities.
            </li>
          </ul>
        </div>
        <div>
          <h3 className={style.portfolioProjectsSectionHeader}>
            Development Phase
          </h3>
          <ul className={style.portfolioProjectsSection}>
            <li className={style.portfolioProjectsSectionElement}>
              &#x2022; Front-End Development: Translate the approved designs
              into responsive web or mobile interfaces, ensuring compatibility
              across browsers and devices.
            </li>
            <li className={style.portfolioProjectsSectionElement}>
              &#x2022; Back-End Development: Implement the platform's core
              functionality, including data handling, calculations, security
              features, and integrations with financial data sources.
            </li>
            <li className={style.portfolioProjectsSectionElement}>
              &#x2022; Security Measures: Implement robust security protocols to
              protect sensitive financial data, including encryption,
              authentication, and access controls.
            </li>
            <li className={style.portfolioProjectsSectionElement}>
              &#x2022; Testing: Conduct thorough testing, including functional,
              usability, performance, and security testing to identify and
              resolve issues.
            </li>
          </ul>
        </div>
        <div>
          <h3 className={style.portfolioProjectsSectionHeader}>
            Integration and Testing
          </h3>
          <ul className={style.portfolioProjectsSection}>
            <li className={style.portfolioProjectsSectionElement}>
              &#x2022; Integrate the financial platform with relevant external
              systems and data sources, such as banking APIs, financial data
              feeds, or third-party tools.
            </li>
            <li className={style.portfolioProjectsSectionElement}>
              &#x2022; Perform comprehensive quality assurance and testing,
              addressing any bugs or issues that arise during development.
            </li>
          </ul>
          {/* <div className={style.textIMGWrapper}>
            <div className={style.textIMGBox}>
              <img className={style.textIMG} src={TESTIMG} alt="" />
            </div>
          </div> */}
        </div>
      </div>
    ),
    // title: "Financial SAAS platform",
    // img: <PortfolioProject1 />,
    // text: "UI/UX design is focused on creating a user-friendly and visually appealing interface for websites and applications. This involves designing the layout, typography, color schemes, and graphics to ensure that the user experience is both enjoyable and intuitive.",
    project2: (
      <div>
        <h2 className={style.portfolioProjectsTitle}>
          UI/UX design for AI Product Creators
        </h2>
        <p className={style.portfolioProjectsTextProject2}>
          In the digital age, a website must be more than just aesthetically
          pleasing; it needs to offer a holistic user experience. Our team
          excels at this, creating websites that are both visually striking and
          rich in content. Our latest project for AI Product Creators is a prime
          example of this fusion.
        </p>
        <div>
          <img
            src={PortfolioProject2IMG1}
            alt=""
            className={style.portfolioProjectsImg}
          />
        </div>
        <p className={style.portfolioProjectsTextProject2}>
          Podcasts are an increasingly popular medium for sharing knowledge,
          stories, and ideas. We recognized the value of integrating podcasts
          into the website, providing a unique auditory dimension for users.
          Here's how we made it happen:
        </p>
        <p className={style.portfolioProjectsTextProject2}>
          <span className={style.portfolioProjectsOutlineText}>
            User-Friendly Player:
          </span>
          We designed an intuitive and accessible podcast player that allows
          visitors to listen to episodes without leaving the website. This keeps
          users engaged and reduces bounce rates.
        </p>
        <p className={style.portfolioProjectsTextProject2}>
          <span className={style.portfolioProjectsOutlineText}>
            Seamless Navigation:
          </span>
          Our layout ensures that podcast episodes are easy to find and explore,
          with user-friendly categories and a visually appealing archive.
        </p>
        <div className={style.portfolioProjectsDoubleImgCase}>
          <img
            src={PortfolioProject2IMG2}
            alt=""
            className={style.portfolioProjects2CaseImg}
          />
          <img
            src={PortfolioProject2IMG3}
            alt=""
            className={style.portfolioProjects2CaseImg}
          />
        </div>
        <h3>
          <span className={style.portfolioProjectsOutlineText}>
            Words that Matter
          </span>
        </h3>
        <p className={style.portfolioProjectsTextProject2}>
          Blogs are a vital component of any website aiming to establish
          authority and keep audiences informed. Here's how we incorporated
          blogging into the design:
        </p>
        <p className={style.portfolioProjectsTextProject2}>
          <span className={style.portfolioProjectsOutlineText}>
            Visually Engaging Layouts:
          </span>
          We developed captivating blog templates that make reading enjoyable.
          High-quality images, clean typography, and thoughtful spacing keep
          users scrolling.
        </p>
        <p className={style.portfolioProjectsTextProject2}>
          <span className={style.portfolioProjectsOutlineText}>
            Content Organization:
          </span>
          Our categorization and tagging system makes it easy for users to find
          articles relevant to their interests. We prioritize user experience by
          ensuring content is always just a click away.
        </p>
        <div>
          <img
            src={PortfolioProject2IMG4}
            alt=""
            className={style.portfolioProjectsImg}
          />
        </div>
        <p className={style.portfolioProjectsTextProject2}>
          <span className={style.portfolioProjectsOutlineText}>
            At Solutio Studio, our mission is to not only create visually
            appealing websites but also to elevate the user experience through
            innovative design strategies. If you're ready to unlock the
            potential of podcasts and blogs on your website, contact us today,
            and let's bring your vision to life.
          </span>
        </p>
      </div>
    ),
    project3: (
      <div>
        <div>
          <h2 className={style.portfolioProjectsTitle}>Eco Application</h2>
          <p className={style.portfolioProjectsTextProject2}>
            In the digital age, a website must be more than just aesthetically
            pleasing; it needs to offer a holistic user experience. Our team
            excels at this, creating websites that are both visually striking
            and rich in content. Our latest project for AI Product Creators is a
            prime example of this fusion.
          </p>
          <h3 className={style.portfolioProjectsCenterHeader}>
            Customer Journey Map
          </h3>
          <div>
            <PortfolioProject3IMG1 className={style.portfolioProjectsImg} />
          </div>
          <h3>
            <span className={style.portfolioProjectsOutlineText}>
              Words that Matter
            </span>
          </h3>
          <p className={style.portfolioProjectsTextProject2}>
            Blogs are a vital component of any website aiming to establish
            authority and keep audiences informed. Here's how we incorporated
            blogging into the design:
          </p>
          <p className={style.portfolioProjectsTextProject2}>
            <span className={style.portfolioProjectsOutlineText}>
              Visually Engaging Layouts:
            </span>
            We developed captivating blog templates that make reading enjoyable.
            High-quality images, clean typography, and thoughtful spacing keep
            users scrolling.
          </p>
          <p className={style.portfolioProjectsTextProject2}>
            <span className={style.portfolioProjectsOutlineText}>
              Content Organization:
            </span>
            Our categorization and tagging system makes it easy for users to
            find articles relevant to their interests. We prioritize user
            experience by ensuring content is always just a click away.
          </p>
          <PorftfolioProjects3IMG2
            className={style.portfolioProjectsFullScreenImg}
          />
          <h3>
            <span className={style.portfolioProjectsOutlineText}>
              Words that Matter
            </span>
          </h3>
          <p className={style.portfolioProjectsTextProject2}>
            Blogs are a vital component of any website aiming to establish
            authority and keep audiences informed. Here's how we incorporated
            blogging into the design:
          </p>
          <p className={style.portfolioProjectsTextProject2}>
            <span className={style.portfolioProjectsOutlineText}>
              Visually Engaging Layouts:
            </span>
            We developed captivating blog templates that make reading enjoyable.
            High-quality images, clean typography, and thoughtful spacing keep
            users scrolling.
          </p>
          <p className={style.portfolioProjectsTextProject2}>
            <span className={style.portfolioProjectsOutlineText}>
              Content Organization:
            </span>
            Our categorization and tagging system makes it easy for users to
            find articles relevant to their interests. We prioritize user
            experience by ensuring content is always just a click away.
          </p>
          <div className={style.portfolioProjectsDoubleImgCase}>
            <img
              src={PorftfolioProjects3IMG3}
              alt=""
              className={style.portfolioProjects3CaseImg}
            />
            <img
              src={PorftfolioProjects3IMG4}
              alt=""
              className={style.portfolioProjects3CaseImg}
            />
            <img
              src={PorftfolioProjects3IMG5}
              alt=""
              className={style.portfolioProjects3CaseImg}
            />
          </div>
          <h3>
            <span className={style.portfolioProjectsOutlineText}>
              Words that Matter
            </span>
          </h3>
          <p className={style.portfolioProjectsTextProject2}>
            Blogs are a vital component of any website aiming to establish
            authority and keep audiences informed. Here's how we incorporated
            blogging into the design:
          </p>
          <p className={style.portfolioProjectsTextProject2}>
            <span className={style.portfolioProjectsOutlineText}>
              Visually Engaging Layouts:
            </span>
            We developed captivating blog templates that make reading enjoyable.
            High-quality images, clean typography, and thoughtful spacing keep
            users scrolling.
          </p>
          <p className={style.portfolioProjectsTextProject2}>
            <span className={style.portfolioProjectsOutlineText}>
              Content Organization:
            </span>
            Our categorization and tagging system makes it easy for users to
            find articles relevant to their interests. We prioritize user
            experience by ensuring content is always just a click away.
          </p>
          <div className={style.portfolioProjectsDoubleImgCase}>
            <img
              src={PorftfolioProjects3IMG6}
              alt=""
              className={style.portfolioProjects3CaseImg}
            />
            <img
              src={PorftfolioProjects3IMG7}
              alt=""
              className={style.portfolioProjects3CaseImg}
            />
            <img
              src={PorftfolioProjects3IMG8}
              alt=""
              className={style.portfolioProjects3CaseImg}
            />
          </div>
          <h3>
            <span className={style.portfolioProjectsOutlineText}>
              Words that Matter
            </span>
          </h3>
          <p className={style.portfolioProjectsTextProject2}>
            Blogs are a vital component of any website aiming to establish
            authority and keep audiences informed. Here's how we incorporated
            blogging into the design:
          </p>
          <p className={style.portfolioProjectsTextProject2}>
            <span className={style.portfolioProjectsOutlineText}>
              Visually Engaging Layouts:
            </span>
            We developed captivating blog templates that make reading enjoyable.
            High-quality images, clean typography, and thoughtful spacing keep
            users scrolling.
          </p>
          <p className={style.portfolioProjectsTextProject2}>
            <span className={style.portfolioProjectsOutlineText}>
              Content Organization:
            </span>
            Our categorization and tagging system makes it easy for users to
            find articles relevant to their interests. We prioritize user
            experience by ensuring content is always just a click away.
          </p>
          <div className={style.portfolioProjectsDoubleImgCase}>
            <img
              src={PorftfolioProjects3IMG9}
              alt=""
              className={style.portfolioProjects3CaseImg}
            />
            <img
              src={PorftfolioProjects3IMG10}
              alt=""
              className={style.portfolioProjects3CaseImg}
            />
            <img
              src={PorftfolioProjects3IMG11}
              alt=""
              className={style.portfolioProjects3CaseImg}
            />
          </div>
          <p className={style.portfolioProjectsTextProject2}>
            <span className={style.portfolioProjectsOutlineText}>
              At Solutio Studio, our mission is to not only create visually
              appealing websites but also to elevate the user experience through
              innovative design strategies. If you're ready to unlock the
              potential of podcasts and blogs on your website, contact us today,
              and let's bring your vision to life.
            </span>
          </p>
        </div>
      </div>
    ),

    project4: (
      <div>
        <h2 className={style.portfolioProjectsTitle}>
          Healthcare SAAS platform
        </h2>
        <p className={style.portfolioProjectsText}>
          We embark on a journey to create cutting-edge financial platforms
          tailored to your unique needs. Our process is a carefully
          choreographed series of steps that blend creativity with technical
          expertise to bring your financial vision to life. From inception to
          deployment and beyond, we navigate this path with precision and
          innovation to ensure your financial platform stands as a beacon of
          success in the digital world.
        </p>
        <div>
          <PortfolioProject4IMG1 className={style.portfolioProjectsImg} />
        </div>
        <p className={style.portfolioProjectsTextProject2}>
          Podcasts are an increasingly popular medium for sharing knowledge,
          stories, and ideas. We recognized the value of integrating podcasts
          into the website, providing a unique auditory dimension for users.
          Here's how we made it happen:
        </p>
        <p className={style.portfolioProjectsTextProject2}>
          <span className={style.portfolioProjectsOutlineText}>
            User-Friendly Player:
          </span>
          We designed an intuitive and accessible podcast player that allows
          visitors to listen to episodes without leaving the website. This keeps
          users engaged and reduces bounce rates.
        </p>
        <p className={style.portfolioProjectsTextProject2}>
          <span className={style.portfolioProjectsOutlineText}>
            Seamless Navigation:
          </span>
          Our layout ensures that podcast episodes are easy to find and explore,
          with user-friendly categories and a visually appealing archive.
        </p>
        <div>
          <PortfolioProject4IMG2 className={style.portfolioProjectsImg} />
        </div>
        <p className={style.portfolioProjectsTextProject2}>
          Podcasts are an increasingly popular medium for sharing knowledge,
          stories, and ideas. We recognized the value of integrating podcasts
          into the website, providing a unique auditory dimension for users.
          Here's how we made it happen:
        </p>
        <p className={style.portfolioProjectsTextProject2}>
          <span className={style.portfolioProjectsOutlineText}>
            User-Friendly Player:
          </span>
          We designed an intuitive and accessible podcast player that allows
          visitors to listen to episodes without leaving the website. This keeps
          users engaged and reduces bounce rates.
        </p>
        <p className={style.portfolioProjectsTextProject2}>
          <span className={style.portfolioProjectsOutlineText}>
            Seamless Navigation:
          </span>
          Our layout ensures that podcast episodes are easy to find and explore,
          with user-friendly categories and a visually appealing archive.
        </p>
      </div>
    ),
  };
  const navigate = useNavigate();

  const buttonText = [
    "Financial SAAS platform",
    "UI/UX design for AI Product Creators",
    "Eco Application",
    "Healthcare SAAS platform",
  ];
  const handlePopState = () => {
    // navigate(-1);
    if (isMobile) {
      isAppPortfolioProjects(false);
      setIsMobileContentFromSolutio(false);
      if (isMobile) {
        setTimeout(() => {
          // const element = document.getElementById("Cases");
          // element.scrollIntoView({ behavior: "auto" });
        }, 250);
      }
    } else {
      isAppPortfolioProjects(false);
    }
  };
  window.addEventListener("popstate", handlePopState);

  const query = window.location.search.substring(1);
  const getProjects = query.split("&");

  let pair = getProjects[0].split("=");

  let currentTextIndex = Number(pair[1].charAt(pair[1].length - 1));

  console.log(currentTextIndex);

  const toNextProject = () => {
    if (pair[1] === "project1") {
      navigate("/portfolioProjects?project=project2");
      return;
    }
    if (pair[1] === "project2") {
      navigate("/portfolioProjects?project=project3");
      return;
    }
    if (pair[1] === "project3") {
      navigate("/portfolioProjects?project=project4");
      return;
    }
    if (pair[1] === "project4") {
      navigate("/portfolioProjects?project=project1");
      return;
    }
  };

  const toPreviousProject = () => {
    if (pair[1] === "project1") {
      navigate("/portfolioProjects?project=project4");
      return;
    }
    if (pair[1] === "project2") {
      navigate("/portfolioProjects?project=project1");
      return;
    }
    if (pair[1] === "project3") {
      navigate("/portfolioProjects?project=project2");
      return;
    }
    if (pair[1] === "project4") {
      navigate("/portfolioProjects?project=project3");
      return;
    }
  };

  return (
    <div
      className={style.portfolioProjects}
      onTouchStart={handleTouchStartMobailScrollDirection}
      onTouchMove={handleTouchMoveMobailScrollDirection}
    >
      <div className={style.backgroundTheme}></div>
      {isMobile ? (
        <div className={style.mobailPortfolioProjectsHeaderBackground}>
          {scrollDirection === "down" ? (
            <ContactUsButton
              className={style.mobailPortfolioProjectsHeaderContactUsButton}
            />
          ) : null}
        </div>
      ) : null}
      <div className={style.portfolioProjectsMain}>
        {/* <Cross className={style.crossIcon} /> */}

        <div className={style.portfolioProjectsContentWrapper}>
          <div
            className={style.buttonCase}
            onClick={() => {
              navigate("/portfolio");
              // setTimeout(() => {
              isAppPortfolioProjects(false);
              // }, 1000);
              setIsMobileContentFromSolutio(false);
              if (isMobile) {
                setTimeout(() => {
                  const element = document.getElementById("Cases");
                  element.scrollIntoView({ behavior: "auto" });
                }, 250);
              }
            }}
          >
            <PortfolioProjectArrow />
            <p className={style.portfolioProjectsButtonCaseText}>Portfolio</p>
          </div>
          {projects[pair[1]]}
        </div>
      </div>
      <footer className={style.portfolioProjectsFooter}>
        <div className={style.footerButtonsCase}>
          <div className={style.footerBackButtonCase}>
            <button
              className={style.portfolioProjectsFooterBackButton}
              onClick={() => {
                toPreviousProject();
                window.scrollTo(0, 0);
              }}
            >
              <PortfolioProjectsFooterBackButtonArrow />
              <p className={style.portfolioProjectsFooterButtonsText}>
                {
                  buttonText[
                    currentTextIndex - 1 == 0 ? 3 : currentTextIndex - 1 - 1
                  ]
                }
              </p>
            </button>
          </div>
          <div className={style.footerButtonCase}>
            <button
              className={style.portfolioProjectsFooterButton}
              onClick={() => {
                toNextProject();
                window.scrollTo(0, 0);
              }}
            >
              <p className={style.portfolioProjectsFooterButtonsText}>
                {
                  buttonText[
                    currentTextIndex - 1 == 3 ? 0 : currentTextIndex - 1 + 1
                  ]
                }
              </p>

              <ButtonArrow />
            </button>
          </div>
        </div>
        <div className={style.portfolioProjectsContactUsButtonCase}>
          <ContactUsButton
            className={style.portfolioProjectsContactUsButton}
            onClick={() => {
              setIsFromPortfolioProjectsToContactUs(true);
            }}
          />
        </div>
        <div className={style.footerInfoCase}>
          <div className={style.footermailRightsBlock}>
            <a className={style.footerInfoLink} href="">
              info@solutio.team
            </a>
            <p className={style.footerRight}>
              © Solutio 2023 All right reserved
            </p>
          </div>
          <div className={style.footerLinksCase}>
            <a
              href="https://www.linkedin.com/company/solutio-studio/about/"
              className={style.footerLink}
              target="_blank"
            >
              <LinkedIn />
            </a>
            <a
              href="https://dribbble.com/solutiostudio"
              className={style.footerLink}
              target="_blank"
            >
              <Dribble />
            </a>
            <a
              href="https://instagram.com/solutio_studio?igshid=NTc4MTIwNjQ2YQ=="
              className={style.footerLink}
              target="_blank"
            >
              <Instagram />
            </a>
          </div>
          <p className={style.footerMobailRight}>
            © Solutio 2023 All right reserved
          </p>
        </div>
      </footer>
    </div>
  );
};

export default PortfolioProjects;
