import React from "react";
import style from "./BackendDevelopment.module.scss";
import BackendIMG from "../../../../../assets/BackendIMG.png";
const BackendDevelopment = React.forwardRef((props, ref, route) => {
  return (
    <div ref={ref} className={style.backendDevelopment}>
      <div className={style.backendDevelopmentDescription}>
        {/* <h3 className={style.backendDevelopmentDescriptionTitle}>
          Backend development
        </h3> */}
        <p className={style.backendDevelopmenttDescriptionText}>
          Backend development is focused on developing the server-side of a
          website or application. It involves writing code that interacts with
          the database and provides functionality such as user authentication,
          data management, and application logic.
        </p>
      </div>

      <div className={style.backendBlock}></div>
    </div>
  );
});

export { BackendDevelopment };
