import React from "react";
import style from "./FullCycle.module.scss";
import FullCircle from "../../../../../assets/FullCycle.png";
const FullCycle = React.forwardRef((props, ref, route) => {
  return (
    <div ref={ref} className={style.fullCycle}>
      <div className={style.fullCycleWrapper}>
        <div className={style.fullCycleDescription}>
          {/* <h3 className={style.fullCycleDescriptionTitle}>Full cycle</h3> */}
          <p className={style.fullCycleDescriptionText}>
            Full cycle creation includes everything from the initial planning
            stage to deployment and maintenance. It involves gathering
            information about the client's business goals and technical
            requirements, creating a project plan, designing the interface,
            writing the code, testing, and deploying the final product.
          </p>
        </div>
        <div className={style.fullCircleBlock}> </div>
      </div>
    </div>
  );
});

export { FullCycle };
