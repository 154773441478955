import React from "react";
import style from "./Header.module.scss";
import { ReactComponent as Logo } from "../../assets/S.S.svg";
import { useNavigate } from "react-router-dom";
import ContactUsButton from "../UI/ContactUsButton/ContactUsButton";
const Header = ({
  setCurrentPage,
  setVioletBackground,
  setIsAppContactUs,
  setPreventServiceRoute,
  setPortfolioTransition,
  setIsOpen,
}) => {
  const navigate = useNavigate();
  return (
    <header className={style.header}>
      <div
        className={style.headerWrapper}
        onClick={() => {
          setPortfolioTransition("/fromServices");
          setVioletBackground(false);
          setCurrentPage("/");
          navigate("/");
          setIsAppContactUs(true);
          setPreventServiceRoute("/");
          setIsOpen(false);
        }}
      >
        <Logo className={style.logo} />
      </div>
    </header>
  );
};

export default Header;
