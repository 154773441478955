import React, { forwardRef } from "react";
import style from "./Design.module.scss";
import DesignIMG from "../../../../../assets/DesignIMG.png";
const Design = forwardRef((props, ref, route) => {
  return (
    <div {...props} ref={ref}>
      <div className={style.designWrapper}>
        <div className={style.designDescription}>
          <p className={style.designDescriptionText}>
            UI/UX design is focused on creating a user-friendly and visually
            appealing interface for websites and applications. This involves
            designing the layout, typography, color schemes, and graphics to
            ensure that the user experience is both enjoyable and intuitive.
          </p>
        </div>

        {/* <DesignIMG className={style.designBlock} /> */}
        <div className={style.designBlock}></div>
      </div>
    </div>
  );
});

export { Design };
