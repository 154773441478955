import React from "react";
import style from "./FrontendDevelopment.module.scss";
import FrontendIMG from "../../../../../assets/FrontendIMG.png";

const FrontendDevelopment = React.forwardRef((props, ref, route) => {
  return (
    <div ref={ref} className={style.frontendDevelopment}>
      <div className={style.frontendDevelopmentDescription}>
        <p className={style.frontendDevelopmentDescriptionText}>
          Frontend development involves building the user-facing portion of a
          website or application, including the design and functionality of the
          interface. This includes writing code using various programming
          languages and frameworks such as HTML, CSS, and JS.
        </p>
      </div>
      <div className={style.frontendBlock}> </div>
    </div>
  );
});

export { FrontendDevelopment };
