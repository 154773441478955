import React from "react";
import style from "./MobileDevelopment.module.scss";
import MobailIMG from "../../../../../assets/MobailIMG.png";
const MobileDevelopment = React.forwardRef((props, ref, route) => {
  return (
    <div ref={ref} className={style.mobileDevelopment}>
      <div className={style.mobileDevelopmentDescription}>
        {/* <h3 className={style.mobileDevelopmentDescriptionTitle}>
          Mobile development
        </h3> */}
        <p className={style.mobileDevelopmentDescriptionText}>
          Mobile development involves creating custom mobile applications for
          smartphones and tablets, which can be native or hybrid, and for
          platforms such as iOS and Android. The goal is to create apps that are
          optimized for mobile screens, offer a high level of performance.
        </p>
      </div>
      <div className={style.frontendBlock}> </div>
    </div>
  );
});

export { MobileDevelopment };
