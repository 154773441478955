import React from "react";
import style from "./Congratulations.module.scss";
import { ReactComponent as Logo } from "../../../Icons/CheckMark.svg";

const Congratulations = () => {
  return (
    <section>
      <div className={style.Congratulations}>
        <div className={style.CongratulationsButton}>
          <Logo alt="CheckMark" className={style.checkMark} />
        </div>

        <h2 className={style.CongratulationsTitle}>Congratulations!</h2>
        <p className={style.CongratulationsText}>
          Your mail sent. <br className={style.br} />
          We will connect with you soon.
        </p>
      </div>
    </section>
  );
};

export { Congratulations };
