import React from "react";
import { useRef, useEffect, useState } from "react";
import style from "./Footer.module.scss";
import Dribble from "../../assets/Dribble";
import Instagram from "../../assets/Instagram";
import LinkedIn from "../../assets/LinkedIn";
import ContactUsButton from "../UI/ContactUsButton/ContactUsButton";
import { useNavigate } from "react-router-dom";
import { InView, useInView } from "react-intersection-observer";
const Footer = ({
  setIsAppContactUs,
  setCurrentPage,
  setFriendsTransition,
  setIsContactsChange,
}) => {
  const ref = useRef();

  const navigate = useNavigate();

  const onMouseWheelDown = () => {};
  const onMouseWheelUp = () => {
    setFriendsTransition("fromContact");
    setCurrentPage("/friends");
    setIsContactsChange(true);
  };

  const onMouseWheel = (e) => {
    window.event.deltaY > 0 ? onMouseWheelDown() : onMouseWheelUp();
  };

  const [scrollDirection, setScrollDirection] = useState("");

  let touchStartY = 0;
  let touchEndY = 0;
  const handleTouchStart = (event) => {
    touchStartY = event.touches[0]?.clientY;
  };
  const handleTouchMove = (event) => {
    touchEndY = event.touches[0].clientY;
  };

  const handleTouchEnd = (event) => {
    if (touchEndY > touchStartY) {
      setScrollDirection("down");
      onMouseWheelUp();
    } else if (touchEndY < touchStartY) {
      setScrollDirection("up");
      onMouseWheelDown();
    } else {
      setScrollDirection("none");
    }
  };

  return (
    <footer
      className={style.footer}
      onWheel={onMouseWheel}
      ref={ref}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      id="Footer"
    >
      <div className={style.footerWrapper} id="Contact" ref={ref}>
        <div className={style.footerCircle}>
          <div className={style.footerCircleContentWrapper}>
            <h2 className={style.footerCircleTitle}>tell us about it</h2>
            <p className={style.footerCircleText}>Have an idea?</p>
            <ContactUsButton
              className={style.footerCircleButton}
              onClick={() => {
                setIsAppContactUs(false);
                // navigate("/contactUs");
              }}
            />
            <div className={style.footerCircleCaseWrapper}>
              <div className={style.footerCircleCase}>
                <a
                  href="mailto:info@solutio.team"
                  className={style.footerCircleCaseText}
                  target="_blank"
                >
                  info@solutio.team
                </a>
              </div>
            </div>
            <div className={style.footerCircleLinks}>
              <a
                href="https://www.linkedin.com/company/solutio-studio/about/"
                target="_blank"
              >
                <LinkedIn />
              </a>
              <a href="https://dribbble.com/solutiostudio" target="_blank">
                <Dribble />
              </a>
              <a
                href="https://instagram.com/solutio_studio?igshid=NTc4MTIwNjQ2YQ=="
                target="_blank"
              >
                <Instagram />
              </a>
            </div>
          </div>

          <div className={style.footerCircleClone}></div>
        </div>
        <p className={style.footerText}>© Solutio 2023 All right reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
