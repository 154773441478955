import React, { useState, useEffect, useRef, useMemo } from "react";
import style from "./ServicesBlock.module.scss";
import "./slickStyle.css"; // Импорт файла со стилями
import { FullCycle } from "./ServicesBlockPages/FullCycle/FullCycle";
import { Design } from "./ServicesBlockPages/Design/Design";
import { MobileDevelopment } from "./ServicesBlockPages/MobileDevelopment/MobileDevelopment";
import { FrontendDevelopment } from "./ServicesBlockPages/FrontendDevelopment/FrontendDevelopment";
import { BackendDevelopment } from "./ServicesBlockPages/BackendDevelopment/BackendDevelopment";
import useDebounce from "../../../Hooks/useDebounce";
import { ReactComponent as LeftArrow } from "../../../assets/LeftArrow.svg";
import { ReactComponent as RightArrow } from "../../../assets/RightArrow.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { pagesArray } from "./ServicesBlockPagesArray";
import { InView, useInView } from "react-intersection-observer";
const ServicesBlock = ({
  setCurrentPage,
  preventServiceRoute,
  setPreventServiceRoute,
  setPortfolioTransition,
  setIsMobileContent,
}) => {
  const [lastPressedButton, setLastPressedButton] = useState();
  const [isMobileServicesMove, setisMobileServicesMove] = useState();
  const [isMobile, setIsMobile] = useState();
  const [route, setRoute] = useState(1);

  const [routeTimeout, setRouteTimeout] = useState("");
  const [block1, setBlock1] = useState(0);
  const [block2, setBlock2] = useState(0);
  const [block3, setBlock3] = useState(0);
  const ref = useRef();
  const refBlock1 = useRef();

  const sliderRef = useRef();
  useEffect(() => {
    if (preventServiceRoute === "portfolio") {
      setRoute(5);
    }
    if (preventServiceRoute === "/") {
      setRoute(1);
    }
  }, []);

  useEffect(() => {
    if (window.screen.width <= 982) {
      setIsMobile((prev) => (prev = true));
    } else {
      setIsMobile((prev) => (prev = false));
    }
  }, []);

  // useEffect(() => {
  //   if (fullCircleView) {
  //     setTimeout(() => {
  //       setRoute((prev) => (prev = 1));
  //     }, 1000);
  //   }
  //   if (designView) {
  //     setTimeout(() => {
  //       setRoute((prev) => (prev = 2));
  //     }, 1000);
  //   }
  //   if (mobailView) {
  //     setTimeout(() => {
  //       setRoute((prev) => (prev = 3));
  //     }, 1000);
  //   }
  //   if (frontedView) {
  //     setTimeout(() => {
  //       setRoute((prev) => (prev = 4));
  //     }, 1000);
  //   }
  //   if (backendView) {
  //     setTimeout(() => {
  //       setRoute((prev) => (prev = 5));
  //     }, 1000);
  //   }
  // }, [fullCircleView, designView, mobailView, frontedView, backendView]);

  // let windowScroll = 0;

  // const controlDirection = () => {
  //   windowScroll = window.scrollY;

  //   if (
  //     windowScroll >=
  //     window.innerHeight + (window.innerWidth >= 475 ? 185 : 132)
  //   ) {
  //     setisMobileServicesMove((prev) => (prev = true));
  //   }
  //   if (
  //     windowScroll <=
  //     window.innerHeight + (window.innerHeight >= 475 ? 185 : 132)
  //   ) {
  //     setisMobileServicesMove((prev) => (prev = false));
  //   }
  //   if (windowScroll >= 4293) {
  //     setisMobileServicesMove((prev) => (prev = false));
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", controlDirection);
  //   return () => {
  //     window.removeEventListener("scroll", controlDirection);
  //   };
  // }, []);

  const onMouseWheelDown = () => {
    setPreventServiceRoute("portfolio");
    setPortfolioTransition("fromServices");
    setCurrentPage("/portfolio");
  };

  const nextSlide = () => {
    clearTimeout(routeTimeout);
    const timeout = setTimeout(() => {
      if (route >= 5) {
        // setCurrentPage("/portfolio");
        setRoute((prev) => (prev = 1));
        return;
      } else {
        // setRoute((prev) => ++prev);
        if (route === 1) {
          setRoute((prev) => (prev = 2));
          return;
        }
        if (route === 2) {
          setRoute((prev) => (prev = 3));
          return;
        }
        if (route === 3) {
          setRoute((prev) => (prev = 4));
          return;
        }
        if (route === 4) {
          setRoute((prev) => (prev = 5));
          return;
        }
      }
    }, 200);
    setRouteTimeout(timeout);
  };
  const previousSlide = () => {
    clearTimeout(routeTimeout);
    const timeout = setTimeout(() => {
      if (route === 1) {
        // setCurrentPage("/");
        setRoute((prev) => (prev = 5));
        return;
      } else {
        // setRoute((prev) => --prev);
        if (route === 2) {
          setRoute((prev) => (prev = 1));
          return;
        }
        if (route === 3) {
          setRoute((prev) => (prev = 2));
          return;
        }
        if (route === 4) {
          setRoute((prev) => (prev = 3));
          return;
        }
        if (route === 5) {
          setRoute((prev) => (prev = 4));
          return;
        }
      }
    }, 500);
    setRouteTimeout(timeout);
  };
  const onMouseWheelUp = () => {
    if (isMobile) {
      setIsMobileContent(false);
      setTimeout(() => {
        setPreventServiceRoute("/");
        setCurrentPage("/");
      }, 1000);
    } else {
      setPreventServiceRoute("/");
      setCurrentPage("/");
    }
  };

  const onMouseWheel = (e) => {
    window.event.deltaY > 0 ? onMouseWheelDown() : onMouseWheelUp();
  };
  const onMouseWheelDownSCroll = () => {
    // setPreventServiceRoute("portfolio");
    // setPortfolioTransition("fromServices");
    // setCurrentPage("/portfolio");
  };

  const onMouseWheelUpScroll = () => {
    if (isMobile) {
      setIsMobileContent(false);
      setTimeout(() => {
        setPreventServiceRoute("/");
        setCurrentPage("/");
      }, 1000);
    }
  };

  const onMouseWheelScroll = (e) => {
    window.event.deltaY > 0 ? onMouseWheelDownSCroll() : onMouseWheelUpScroll();
  };
  // ref?.current?.addEventListener("wheel", (event) => event.preventDefault());
  if (!isMobile) {
    const ppp = () => {
      if (block1 <= -1203) {
        setBlock1((prev) => (prev = 2000));
      } else {
        setBlock1((prev) => (prev = prev - 1.5));
      }
    };

    requestAnimationFrame(ppp);

    const ppp2 = () => {
      if (block2 <= -2203) {
        setBlock2((prev) => (prev = 1000));
      } else {
        setBlock2((prev) => (prev = prev - 1.5));
      }
    };
    requestAnimationFrame(ppp2);

    const ppp3 = () => {
      if (block3 <= -3203) {
        setBlock3((prev) => (prev = 0));
      } else {
        setBlock3((prev) => (prev = prev - 1.5));
      }
    };

    requestAnimationFrame(ppp3);
  } else {
    const ppp = () => {
      if (block1 <= -1003) {
        setBlock1((prev) => (prev = 2000));
      } else {
        setBlock1((prev) => (prev = prev - 1.5));
      }
    };

    requestAnimationFrame(ppp);

    const ppp2 = () => {
      if (block2 <= -2003) {
        setBlock2((prev) => (prev = 1000));
      } else {
        setBlock2((prev) => (prev = prev - 1.5));
      }
    };
    requestAnimationFrame(ppp2);

    const ppp3 = () => {
      if (block3 <= -3003) {
        setBlock3((prev) => (prev = 0));
      } else {
        setBlock3((prev) => (prev = prev - 1.5));
      }
    };

    requestAnimationFrame(ppp3);
  }

  const getWrappedElement = (element) => {
    return (
      <ul className={style.servicesBlockServicesList} ref={ref}>
        <div
          className={style.navBlock1}
          style={{ left: block1 }}
          ref={refBlock1}
        >
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 1
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                if (route === 5) {
                  setLastPressedButton((prev) => (prev = "firstMenuButton"));
                }
                // if (route === 5) {
                //   if (sliderRef.current) {
                //     sliderRef.current.slickPrev();
                //   }
                // }
                setRoute((prev) => (prev = 1));
              }}
            >
              Full cycle
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 2
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 2));
                setLastPressedButton("menu");
              }}
            >
              UI / UX Design
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 3
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 3));
                setLastPressedButton("menu");
              }}
            >
              Mobile Development
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 4
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 4));
                setLastPressedButton("menu");
              }}
            >
              Frontend Development
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 5
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                if (route === 1) {
                  setLastPressedButton((prev) => (prev = "lastMenuButton"));
                }
                // if (route === 1) {
                //   if (sliderRef.current) {
                //     sliderRef.current.slickPrev();
                //   }
                // }
                setRoute((prev) => (prev = 5));
              }}
            >
              Backend Development
            </div>
          </li>
        </div>
        <div className={style.navBlock2} style={{ left: block2 }}>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 1
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                if (route === 5) {
                  setLastPressedButton((prev) => (prev = "firstMenuButton"));
                }
                // if (route === 5) {
                //   if (sliderRef.current) {
                //     sliderRef.current.slickPrev();
                //   }
                // }
                setRoute((prev) => (prev = 1));
              }}
            >
              Full cycle
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 2
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 2));
                setLastPressedButton("menu");
              }}
            >
              UI / UX Design
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 3
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 3));
                setLastPressedButton("menu");
              }}
            >
              Mobile Development
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 4
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 4));
                setLastPressedButton("menu");
              }}
            >
              Frontend Development
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 5
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                // if (route === 1) {
                //   if (sliderRef.current) {
                //     sliderRef.current.slickPrev();
                //   }
                // }
                if (route === 1) {
                  setLastPressedButton((prev) => (prev = "lastMenuButton"));
                }
                setRoute((prev) => (prev = 5));
              }}
            >
              Backend Development
            </div>
          </li>
        </div>
        <div className={style.navBlock2} style={{ left: block3 }}>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 1
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                if (route === 5) {
                  setLastPressedButton((prev) => (prev = "firstMenuButton"));
                }
                // if (route === 5) {
                //   if (sliderRef.current) {
                //     sliderRef.current.slickNext();
                //   }
                // }
                setRoute((prev) => (prev = 1));
              }}
            >
              Full cycle
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 2
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 2));
                setLastPressedButton("menu");
              }}
            >
              UI / UX Design
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 3
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 3));
                setLastPressedButton("menu");
              }}
            >
              Mobile Development
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 4
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 4));
                setLastPressedButton("menu");
              }}
            >
              Frontend Development
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 5
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                if (route === 1) {
                  setLastPressedButton((prev) => (prev = "lastMenuButton"));
                }
                // if (route === 1) {
                //   if (sliderRef.current) {
                //     sliderRef.current.slickPrev();
                //   }
                // }
                setRoute((prev) => (prev = 5));
              }}
            >
              Backend Development
            </div>
          </li>
        </div>
      </ul>
    );
  };
  const wrappedElement = useMemo(
    () =>
      getWrappedElement(
        <ul className={style.servicesBlockServicesList} ref={ref}>
          <div
            className={style.navBlock1}
            style={{ left: block1 }}
            ref={refBlock1}
          >
            <li className={style.servicesBlockServicesListItem}>
              <div
                className={
                  route === 1
                    ? style.activeLink
                    : style.servicesBlockServicesListItemText
                }
                onClick={() => {
                  setRoute((prev) => (prev = 1));
                }}
              >
                Full cycle
              </div>
            </li>
            <li className={style.servicesBlockServicesListItem}>
              <div
                className={
                  route === 2
                    ? style.activeLink
                    : style.servicesBlockServicesListItemText
                }
                onClick={() => {
                  setRoute((prev) => (prev = 2));
                }}
              >
                UI / UX Design
              </div>
            </li>
            <li className={style.servicesBlockServicesListItem}>
              <div
                className={
                  route === 3
                    ? style.activeLink
                    : style.servicesBlockServicesListItemText
                }
                onClick={() => {
                  setRoute((prev) => (prev = 3));
                }}
              >
                Mobile Development
              </div>
            </li>
            <li className={style.servicesBlockServicesListItem}>
              <div
                className={
                  route === 4
                    ? style.activeLink
                    : style.servicesBlockServicesListItemText
                }
                onClick={() => {
                  setRoute((prev) => (prev = 4));
                }}
              >
                Frontend Development
              </div>
            </li>
            <li className={style.servicesBlockServicesListItem}>
              <div
                className={
                  route === 5
                    ? style.activeLink
                    : style.servicesBlockServicesListItemText
                }
                onClick={() => {
                  // if (route === 1) {
                  //   sliderRef.current.slickNext();
                  // }
                  setRoute((prev) => (prev = 5));
                }}
              >
                Backend Development
              </div>
            </li>
          </div>
          <div className={style.navBlock2} style={{ left: block2 }}>
            <li className={style.servicesBlockServicesListItem}>
              <div
                className={
                  route === 1
                    ? style.activeLink
                    : style.servicesBlockServicesListItemText
                }
                onClick={() => {
                  setRoute((prev) => (prev = 1));
                }}
              >
                Full cycle
              </div>
            </li>
            <li className={style.servicesBlockServicesListItem}>
              <div
                className={
                  route === 2
                    ? style.activeLink
                    : style.servicesBlockServicesListItemText
                }
                onClick={() => {
                  setRoute((prev) => (prev = 2));
                }}
              >
                UI / UX Design
              </div>
            </li>
            <li className={style.servicesBlockServicesListItem}>
              <div
                className={
                  route === 3
                    ? style.activeLink
                    : style.servicesBlockServicesListItemText
                }
                onClick={() => {
                  setRoute((prev) => (prev = 3));
                }}
              >
                Mobile Development
              </div>
            </li>
            <li className={style.servicesBlockServicesListItem}>
              <div
                className={
                  route === 4
                    ? style.activeLink
                    : style.servicesBlockServicesListItemText
                }
                onClick={() => {
                  setRoute((prev) => (prev = 4));
                }}
              >
                Frontend Development
              </div>
            </li>
            <li className={style.servicesBlockServicesListItem}>
              <div
                className={
                  route === 5
                    ? style.activeLink
                    : style.servicesBlockServicesListItemText
                }
                onClick={() => {
                  setRoute((prev) => (prev = 5));
                }}
              >
                Backend Development
              </div>
            </li>
          </div>
          <div className={style.navBlock2} style={{ left: block3 }}>
            <li className={style.servicesBlockServicesListItem}>
              <div
                className={
                  route === 1
                    ? style.activeLink
                    : style.servicesBlockServicesListItemText
                }
                onClick={() => {
                  setRoute((prev) => (prev = 1));
                }}
              >
                Full cycle
              </div>
            </li>
            <li className={style.servicesBlockServicesListItem}>
              <div
                className={
                  route === 2
                    ? style.activeLink
                    : style.servicesBlockServicesListItemText
                }
                onClick={() => {
                  setRoute((prev) => (prev = 2));
                }}
              >
                UI / UX Design
              </div>
            </li>
            <li className={style.servicesBlockServicesListItem}>
              <div
                className={
                  route === 3
                    ? style.activeLink
                    : style.servicesBlockServicesListItemText
                }
                onClick={() => {
                  setRoute((prev) => (prev = 3));
                }}
              >
                Mobile Development
              </div>
            </li>
            <li className={style.servicesBlockServicesListItem}>
              <div
                className={
                  route === 4
                    ? style.activeLink
                    : style.servicesBlockServicesListItemText
                }
                onClick={() => {
                  setRoute((prev) => (prev = 4));
                }}
              >
                Frontend Development
              </div>
            </li>
            <li className={style.servicesBlockServicesListItem}>
              <div
                className={
                  route === 5
                    ? style.activeLink
                    : style.servicesBlockServicesListItemText
                }
                onClick={() => {
                  setRoute((prev) => (prev = 5));
                }}
              >
                Backend Development
              </div>
            </li>
          </div>
        </ul>
      ),
    [
      <ul className={style.servicesBlockServicesList} ref={ref}>
        <div
          className={style.navBlock1}
          style={{ left: block1 }}
          ref={refBlock1}
        >
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 1
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 1));
              }}
            >
              Full cycle
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 2
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 2));
              }}
            >
              UI / UX Design
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 3
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 3));
              }}
            >
              Mobile Development
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 4
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 4));
              }}
            >
              Frontend Development
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 5
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 5));
              }}
            >
              Backend Development
            </div>
          </li>
        </div>
        <div className={style.navBlock2} style={{ left: block2 }}>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 1
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 1));
              }}
            >
              Full cycle
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 2
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 2));
              }}
            >
              UI / UX Design
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 3
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 3));
              }}
            >
              Mobile Development
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 4
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 4));
              }}
            >
              Frontend Development
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 5
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 5));
              }}
            >
              Backend Development
            </div>
          </li>
        </div>
        <div className={style.navBlock2} style={{ left: block3 }}>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 1
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 1));
              }}
            >
              Full cycle
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 2
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 2));
              }}
            >
              UI / UX Design
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 3
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 3));
              }}
            >
              Mobile Development
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 4
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 4));
              }}
            >
              Frontend Development
            </div>
          </li>
          <li className={style.servicesBlockServicesListItem}>
            <div
              className={
                route === 5
                  ? style.activeLink
                  : style.servicesBlockServicesListItemText
              }
              onClick={() => {
                setRoute((prev) => (prev = 5));
              }}
            >
              Backend Development
            </div>
          </li>
        </div>
      </ul>,
    ]
  );

  const [scrollDirection, setScrollDirection] = useState();
  const [scrollStartDirection, setScrollStartDirection] = useState();
  const [currentRoute, setCurrentRoute] = useState();

  let touchStartY;
  let touchEndY;
  const handleTouchStart = (event) => {
    setScrollStartDirection(event.touches[0]?.clientY);
    setCurrentRoute(route);
  };
  const handleTouchMove = (event) => {
    setScrollDirection(event.touches[0]?.clientY);
    // touchEndY = event.touches[0]?.clientY;
    // console.log("ENDY" + touchEndY);
    // setScrollDirection(touchEndY);
  };

  const handleTouchEnd = () => {
    if (window.scrollY === 0) {
      if (150 < scrollDirection - scrollStartDirection) {
        setTimeout(() => {
          if (currentRoute === route) {
            onMouseWheelUpScroll();
          }
        }, 100);
      }
    }
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (currentSlide) => {
      setRoute(currentSlide + 1);
    },
  };
  const goToSlide = (index) => {
    if (sliderRef.current) {
      if (lastPressedButton === "Left") {
        sliderRef.current.slickPrev();
      }
      if (lastPressedButton === "Right") {
        sliderRef.current.slickNext();
      }
      if (lastPressedButton === "menu") {
        sliderRef.current.slickGoTo(index);
      }
      if (lastPressedButton === "firstMenuButton") {
        sliderRef.current.slickNext(index);
      }
      if (lastPressedButton === "lastMenuButton") {
        sliderRef.current.slickPrev();
      }
    }
  };
  useEffect(() => {
    if (route === 1) {
      goToSlide(0);
    }
    if (route === 2) {
      goToSlide(1);
    }
    if (route === 3) {
      goToSlide(2);
    }
    if (route === 4) {
      goToSlide(3);
    }
    if (route === 5) {
      goToSlide(4);
    }
  }, [route]);

  return (
    <section
      className={style.servicesBlock}
      onWheel={onMouseWheel}
      id="AboutUs"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {" "}
      {/* <div className={style.LOLOLO}> */}
      <div className={style.servicesBlockSubtitle}>
        <div className={style.servicesBlockSubtitleWrapper}>
          <p className={style.servicesBlockSubtitleTitle}>our Services</p>
          <h2
            className={
              isMobile
                ? isMobileServicesMove
                  ? style.servicesBlockMobailMoveSubtitleText
                  : style.servicesBlockSubtitleText
                : style.servicesBlockSubtitleText
            }
          >
            What do we provide?
          </h2>
        </div>
      </div>
      {wrappedElement}
      {/* </div> */}
      {/* {isMobile ? (
        <ul
          className={
            isMobile
              ? isMobileServicesMove
                ? style.servicesBlockMobailMoveServicesList
                : style.servicesBlockMobailServicesList
              : null
          }
        >
          <li className={style.servicesBlockMobileServicesListItem}>
            {pagesArray[route - 1]}
          </li>
          <li className={style.servicesBlockMobileActiveServicesListItem}>
            <p>{pagesArray[route]}</p>
          </li>
          <li className={style.servicesBlockMobileServicesListItem}>
            {pagesArray[route + 1]}
          </li>
        </ul>
      ) : ( */}
      {/* )} */}
      {/* <div> */}{" "}
      <div className={style.servicesDesktopBlockPagesWrapper}>
        <button
          className={style.servicesBackButton}
          onClick={() => {
            previousSlide();
            setLastPressedButton("Left");
          }}
        >
          <LeftArrow />
        </button>
        <div className={style.servicesDesktopBlockPages}>
          <Slider {...settings} ref={sliderRef}>
            <FullCycle route={route} />
            <Design route={route} />
            <MobileDevelopment route={route} />
            <FrontendDevelopment route={route} />
            <BackendDevelopment route={route} />
          </Slider>
        </div>
        <button
          className={style.servicesButton}
          onClick={() => {
            nextSlide();
            setLastPressedButton("Right");
          }}
        >
          <RightArrow />
        </button>
      </div>
      <div className={style.servicesMobailBlockPagesFooter}>
        {/* <button
          className={style.servicesMobileBackButton}
          onClick={previousSlide}
        >
          <LeftArrow />
        </button> */}

        <div className={style.servicesMobailBlockPagesCircles}>
          <div
            className={
              route === 1
                ? style.servicesMobailBlockPagesCirclesItemActive
                : style.servicesMobailBlockPagesCirclesItem
            }
            onClick={() => {
              if (route === 5) {
                setLastPressedButton((prev) => (prev = "firstMenuButton"));
              }
              setRoute((prev) => (prev = 1));
            }}
          ></div>
          <div
            className={
              route === 2
                ? style.servicesMobailBlockPagesCirclesItemActive
                : style.servicesMobailBlockPagesCirclesItem
            }
            onClick={() => {
              setLastPressedButton("menu");
              setRoute((prev) => (prev = 2));
            }}
          ></div>
          <div
            className={
              route === 3
                ? style.servicesMobailBlockPagesCirclesItemActive
                : style.servicesMobailBlockPagesCirclesItem
            }
            onClick={() => {
              setLastPressedButton("menu");
              setRoute((prev) => (prev = 3));
            }}
          ></div>
          <div
            className={
              route === 4
                ? style.servicesMobailBlockPagesCirclesItemActive
                : style.servicesMobailBlockPagesCirclesItem
            }
            onClick={() => {
              setLastPressedButton("menu");
              setRoute((prev) => (prev = 4));
            }}
          ></div>
          <div
            className={
              route === 5
                ? style.servicesMobailBlockPagesCirclesItemActive
                : style.servicesMobailBlockPagesCirclesItem
            }
            onClick={() => {
              if (route === 1) {
                setLastPressedButton((prev) => (prev = "lastMenuButton"));
              }
              setRoute((prev) => (prev = 5));
            }}
          ></div>
        </div>

        {/* <button className={style.servicesMobailButton} onClick={nextSlide}>
          <RightArrow />
        </button> */}
      </div>
      {/* <div className={style.buttonsCase}>
        <button className={style.servicesBackButton} onClick={onMouseWheelUp}>
          <BackButtonArrow />
          Previous Slide
        </button>
        <button className={style.servicesButton} onClick={onMouseWheelDown}>
          Next slide
          <ButtonArrow />
        </button>
      </div> */}
      {/* </div> */}
    </section>
  );
};

export default ServicesBlock;
