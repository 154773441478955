import React from "react";

const ContactButton = ({ ...props }) => {
  return (
    <button type="button" {...props}>
      {props.text}
    </button>
  );
};

export default ContactButton;
