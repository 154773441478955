import React, { useEffect, useRef } from "react";
import style from "./Main.module.scss";
import ServicesBlock from "./ServicesBlock/ServicesBlock";
import TitlePage from "./TitlePage/TitlePage";
import { Routes, Route } from "react-router-dom";
import { Congratulations } from "./Congratulations/Congratulations";
import ContactUs from "./ContactUs/ContactUs";
import Portfolio from "./Portfolio/Portfolio";
import PortfolioProjects from "./PortfolioProjects/PortfolioProjects";
import { ReactComponent as PortfolioProjects1 } from "../../assets/PortfolioProjects1.svg";
import { useState } from "react";
import FriendsPage from "./FriendsPage/FriendsPage";
import Footer from "../Footer/Footer";
import { Outlet } from "react-router-dom";

const Main = ({
  isAppPortfolioProjects,
  setIsAppContactUs,
  setPortfolioData,
  setChangeMyURL,
  setCurrentPage,
  setPortfolioTransition,
  portfolioTransition,
  portfolioSCroll,
  setPortfolioSCroll,
  setFriendsTransition,
  setIsFriendsChange,
  isMobile,
}) => {
  const [isFooter, setIsFooter] = useState(false);
  const portfolioRef = useRef();
  const friendsRef = useRef();
  const mainRef = useRef();
  // useEffect(() => {
  //   if (portfolioRef.current) {
  //     const elementHeight = portfolioRef.current.offsetHeight;
  //     if (window.event.deltaY === elementHeight) {
  //       if (friendsRef.current) {
  //         friendsRef.current.scrollIntoView({
  //           behavior: "smooth",
  //           block: "end",
  //         });
  //       }
  //     }
  //     console.log(elementHeight);
  //   }
  // });

  const onMouseWheelDown = () => {
    // if (mainRef.current) {
    //   mainRef.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "end",
    //   });
    // }
  };

  const onMouseWheelUp = () => {};

  const onMouseWheel = (e) => {
    window.scrollY > 2335 ? onMouseWheelDown() : onMouseWheelUp();
  };

  // console.log(mainRef);

  return (
    <main ref={mainRef} id="main" className={style.mainPage}>
      <Portfolio
        portfolioData={setPortfolioData}
        setIsAppContactUs={setIsAppContactUs}
        setChangeMyURL={setChangeMyURL}
        setCurrentPage={setCurrentPage}
        ref={portfolioRef}
        setPortfolioTransition={setPortfolioTransition}
        portfolioTransition={portfolioTransition}
        portfolioSCroll={portfolioSCroll}
        setPortfolioSCroll={setPortfolioSCroll}
        setFriendsTransition={setFriendsTransition}
        setIsFriendsChange={setIsFriendsChange}
        isMobile={isMobile}
      />
      {/* <FriendsPage ref={friendsRef} setIsFooter={setIsFooter} /> */}

      {/* <Footer isFooter={isFooter} setIsFooter={setIsFooter} /> */}
      <Routes>
        {/* <Route
          path="/portfolio/contacts"
          element={
            <div
              className={
                currentPage === "/portfolio/contacts"
                  ? style.footerPageWrapper
                  : style.footerPageWrapperHide
              }
            >
              <Footer
                setIsAppContactUs={setIsAppContactUs}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                isContactUs={setIsAppContactUs}
              />
            </div>
          }
        /> */}
        <Route path="/congratulations" element={<Congratulations />} />
      </Routes>
      <Outlet />
    </main>
  );
};

export default Main;
