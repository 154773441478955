import React, { useEffect } from "react";
import style from "./ContactUs.module.scss";
import ContactButton from "../../UI/ContactButton/ContactButton";
import ContactInput from "../../UI/ContactInput/ContactInput";
import { ReactComponent as Cross } from "../../../Icons/Cross.svg";
import { useRef, useState } from "react";
import { ReactComponent as Logo } from "../../../assets/S.S.svg";
import { useNavigate } from "react-router-dom";

const ContactUs = ({
  setIsAppContactUs,
  setIsFirstTime,
  isMobile,
  setIsMobileContentFromSolutio,
  setIsFromContactUs,
  setIsAppPortfolioProjects,
  isFromPortfolioProjectsToContactUs,
  setIsFromPortfolioProjectsToContactUs,
}) => {
  useEffect(() => {
    setIsAppContactUs(false);
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [data, setData] = useState({
    name: "",
    from: "",
    discus: {
      saas: false,
      mobile: false,
      web: false,
      landingPage: false,
    },
    interested: "",
    budget: "",
    email: "",
  });

  // useEffect(() => {
  //   document.body.style.overflow = "visible";
  //   if (isTitlePage) {
  //     return (document.body.style.overflow = "hidden");
  //   }
  // });

  const [value, setValue] = useState("");
  const textareaLineHeight = 24;

  let rows = 1;

  const handleChange = (event) => {
    setValue(event.target.value);
    const previousRows = event.target.rows;
    event.target.rows = 1; // обнуляем количество строк
    const currentRows = Math.ceil(
      event.target.scrollHeight / textareaLineHeight
    );
    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    } else {
      event.target.rows = currentRows - 1;
    }
  };

  const handlePopState = () => {
    setIsMobileContentFromSolutio(false);
    setIsAppContactUs(true);
    setIsFromContactUs(true);
    setIsAppPortfolioProjects(true);
  };
  window.addEventListener("popstate", handlePopState);

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.scrollTo(0, window.scrollY + 1);
  //   }, 1000);
  // }, []);
  return (
    <form className={style.contactUs}>
      {isMobile ? (
        <div className={style.mobailContactUsHeaderBackground}></div>
      ) : null}
      <Cross
        className={style.crossIcon}
        onClick={() => {
          setTimeout(() => {
            setIsAppContactUs(true);
            setIsFirstTime(true);
            navigate(-1);
            setIsMobileContentFromSolutio(false);
            if (isFromPortfolioProjectsToContactUs) {
              setIsAppPortfolioProjects(true);
              setIsFromPortfolioProjectsToContactUs(false);
            }
            setIsFromContactUs(true);
          }, 100);
        }}
      />
      <h2 className={style.title}>contact us</h2>
      <h3 className={style.subTitle}>
        Hey, share us your idea and lets start!
      </h3>
      <div className={style.nameCase}>
        <p className={style.inputTitle}>My name is</p>
        <ContactInput
          className={style.input}
          placeholder="your name"
          onChange={(event) =>
            setData((prev) => ({
              ...prev,
              name: event.target.value,
            }))
          }
        />
        <p className={style.inputTitle}>from</p>
        <ContactInput
          className={style.input}
          placeholder="website or company name"
          onChange={(event) =>
            setData((prev) => ({
              ...prev,
              from: event.target.value,
            }))
          }
        />
      </div>
      <p className={style.buttonTitle}>I`d like to discuss...</p>
      <div className={style.discussButtonBlock}>
        <div className={style.discussButtonCase}>
          <ContactButton
            text={"SAAS"}
            className={
              data.discus.saas ? style.discussButtonActive : style.discussButton
            }
            onClick={() =>
              setData((prev) => ({
                ...prev,
                discus: {
                  ...prev.discus,
                  saas: !prev.discus.saas,
                },
              }))
            }
          />
          <ContactButton
            text={"Mobile"}
            className={
              data.discus.mobile
                ? style.discussButtonActive
                : style.discussButton
            }
            onClick={() =>
              setData((prev) => ({
                ...prev,
                discus: {
                  ...prev.discus,
                  mobile: !prev.discus.mobile,
                },
              }))
            }
          />
        </div>
        <div className={style.discussButtonCase}>
          <ContactButton
            text={"Web"}
            className={
              data.discus.web ? style.discussButtonActive : style.discussButton
            }
            onClick={() =>
              setData((prev) => ({
                ...prev,
                discus: {
                  ...prev.discus,
                  web: !prev.discus.web,
                },
              }))
            }
          />
          <ContactButton
            text={"Landing page"}
            className={
              data.discus.landingPage
                ? style.discussButtonActive
                : style.discussButton
            }
            onClick={() =>
              setData((prev) => ({
                ...prev,
                discus: {
                  ...prev.discus,
                  landingPage: !prev.discus.landingPage,
                },
              }))
            }
          />
        </div>
      </div>
      <p className={style.buttonTitle}>I`m interested in...</p>
      <div className={style.interestedButtonBlock}>
        <div className={style.interestedButtonCase}>
          <ContactButton
            text={"Design"}
            className={
              data.interested === "design"
                ? style.interestedButtonActive
                : style.interestedButton
            }
            onClick={() =>
              setData((prev) => ({
                ...prev,
                interested: "design",
              }))
            }
          />
          <ContactButton
            text={"Development"}
            className={
              data.interested === "development"
                ? style.interestedButtonActive
                : style.interestedButton
            }
            onClick={() =>
              setData((prev) => ({
                ...prev,
                interested: "development",
              }))
            }
          />
        </div>
        <div className={style.interestedButtonCase}>
          <ContactButton
            text={"Design & Development"}
            className={
              data.interested === "design&development"
                ? style.interestedButtonActive
                : style.interestedButton
            }
            onClick={() =>
              setData((prev) => ({
                ...prev,
                interested: "design&development",
              }))
            }
          />
        </div>
      </div>
      <textarea
        rows={rows}
        className={style.input}
        placeholder="Tell us about your project"
        value={value}
        onChange={handleChange}
      ></textarea>
      <p className={style.buttonTitle}>Project budget (USD)</p>
      <div className={style.budgetButtonBlock}>
        <div className={style.budgetButtonCase}>
          <ContactButton
            text={"less than 10"}
            className={
              data.budget === "less than 10"
                ? style.budgetButtonActive
                : style.budgetButton
            }
            onClick={() =>
              setData((prev) => ({
                ...prev,
                budget: "less than 10",
              }))
            }
          />
          <ContactButton
            text={"10-20k"}
            className={
              data.budget === "10-20k"
                ? style.budgetButtonActive
                : style.budgetButton
            }
            onClick={() =>
              setData((prev) => ({
                ...prev,
                budget: "10-20k",
              }))
            }
          />
        </div>
        <div className={style.budgetButtonCase}>
          <ContactButton
            text={"30-40k"}
            className={
              data.budget === "30-40k"
                ? style.budgetButtonActive
                : style.budgetButton
            }
            onClick={() =>
              setData((prev) => ({
                ...prev,
                budget: "30-40k",
              }))
            }
          />
          <ContactButton
            text={"40-50k"}
            className={
              data.budget === "40-50k"
                ? style.budgetButtonActive
                : style.budgetButton
            }
            onClick={() =>
              setData((prev) => ({
                ...prev,
                budget: "40-50k",
              }))
            }
          />
        </div>
        <div className={style.budgetButtonCase}>
          <ContactButton
            text={"50-100k"}
            className={
              data.budget === "50-100k"
                ? style.budgetButtonActive
                : style.budgetButton
            }
            onClick={() =>
              setData((prev) => ({
                ...prev,
                budget: "50-100k",
              }))
            }
          />
          <ContactButton
            text={"more than 100k"}
            className={
              data.budget === "more than 100k"
                ? style.budgetButtonActive
                : style.budgetButton
            }
            onClick={() =>
              setData((prev) => ({
                ...prev,
                budget: "more than 100k",
              }))
            }
          />
        </div>
      </div>
      <div className={style.contactBlock}>
        <p className={style.inputTitle}>Contact me back at</p>
        <ContactInput
          className={style.input}
          placeholder="your email"
          onChange={(event) =>
            setData((prev) => ({
              ...prev,
              email: event.target.value,
            }))
          }
        />
      </div>
      <div className={style.sendButtonCase}>
        <button className={style.sendButton} type="button">
          Send
        </button>
      </div>
    </form>
  );
};

export default ContactUs;
