import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import style from "./FriendsPage.module.scss";
import { InView, useInView } from "react-intersection-observer";
import useString from "../../../Hooks/useString";
import blot1 from "../../../assets/blot1.png";
import blot2 from "../../../assets/blot2.png";
import blot3 from "../../../assets/blot3.png";
import blot4 from "../../../assets/blot4.png";
import blot5 from "../../../assets/blot5.png";
import blot6 from "../../../assets/blot6.png";
const FriendsPage = React.forwardRef(
  ({
    currentPage,
    setIsFooter,
    setCurrentPage,
    setPortfolioTransition,
    portfolioTransition,
    friendsTransition,
    setFriendsTransition,
    setIsFriendsChange,
    setIsContactsChange,
    isFriendsChange,
    isContactsChange,
    isMobile,
  }) => {
    // const { ref: friendsPageRef, inView: inView } = useInView({
    //   threshold: 0.8,
    // });

    const friendsRef = useRef();

    // const p = () => {
    //   if (friendsRef.current) {
    //     friendsRef.current.scrollIntoView({
    //       behavior: "smooth",
    //       block: "end",
    //     });
    //   }
    // };

    // useEffect(() => {
    //   // console.log(inView);
    //   if (inView) {
    //     setTimeout(() => {
    //       if (friendsRef.current) {
    //         friendsRef.current.scrollIntoView({
    //           behavior: "smooth",
    //           block: "end",
    //         });
    //       }
    //     }, 100);
    //   }
    // }, [inView]);

    // console.log(friendsRef.current);
    // useEffect(() => {
    //   //   const controllScroll = () => {
    //   setScroll(window.scrollY);
    //   if (scroll <= 2335) {
    //     setlolo(true);
    //   }
    //   //   };
    //   //   window.addEventListener("scroll", controllScroll);
    //   //   return () => {
    //   //     window.removeEventListener("scroll", controllScroll);
    //   //   };
    // }, []);
    // console.log(ref);

    const setFriendsTransitionStyle = () => {
      if (friendsTransition === "fromPortfolio") {
        return style.canvasWrapper;
      }
      if (friendsTransition === "toPortfolio") {
        return style.canvasWrapperHide;
      }
      if (friendsTransition === "fromContact") {
        return style.canvasWrapperHideFromContact;
      }
      if (friendsTransition === "toContact") {
        return style.canvasWrapperToContact;
      }
    };

    const setFriendsTextTransitionStyle = () => {
      if (friendsTransition === "fromPortfolio") {
        return style.friendsPageSubtitleClone;
      }
      if (friendsTransition === "toPortfolio") {
        return style.friendsPageSubtitleCloneHide;
      }
      if (friendsTransition === "fromContact") {
        return style.friendsPageSubtitleCloneFromContact;
      }
      if (friendsTransition === "toContact") {
        return style.friendsPageSubtitleCloneToContact;
      }
    };

    useEffect(() => {
      setTimeout(() => {
        setIsFriendsChange(false);
      }, 500);
    }, []);
    useEffect(() => {
      setTimeout(() => {
        setIsContactsChange(false);
      }, 500);
    }, []);

    const onMouseWheelDown = () => {
      if (!isFriendsChange) {
        setCurrentPage("/contacts");
        setFriendsTransition("toContact");
      }
    };

    const onMouseWheelUp = () => {
      if (!isContactsChange) {
        setPortfolioTransition("fromContact");
        setFriendsTransition("toPortfolio");
        setCurrentPage("/portfolio");
      }
    };

    const onMouseWheel = (e) => {
      window.event.deltaY > 0 ? onMouseWheelDown() : onMouseWheelUp();
    };

    // if (friendsRef.current) {
    //   const scrollTop = friendsRef.current.scrollTop;

    //   console.log("Позиция скролла:", scrollTop);
    // }

    const canvasRef = useRef(null);
    const canvasRef2 = useRef(null);
    const canvasRef3 = useRef(null);
    const canvasRef4 = useRef(null);
    const canvasRef5 = useRef(null);
    const canvasRef6 = useRef(null);
    const canvasRef7 = useRef(null);
    const canvasRef8 = useRef(null);
    const canvasRef9 = useRef(null);
    const canvasRef10 = useRef(null);
    const canvasRef11 = useRef(null);
    const canvasRef12 = useRef(null);

    // const string = useString();

    // useEffect(() => {
    //   if (canvasRef.current) {
    //     string(canvasRef.current, "#CBC9E2");
    //   }
    // }, []);

    useEffect(() => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      let wasCenter = false;
      const centerY = canvas.height / 2;
      const endX = canvas.width;

      ctx.beginPath();
      ctx.moveTo(0, centerY);
      ctx.lineTo(endX, centerY);
      ctx.lineWidth = 5;
      ctx.strokeStyle = "#CBC9E2";
      ctx.stroke();

      const handleMouseMove = (event) => {
        const rect = canvas.getBoundingClientRect();
        let mouseY;
        mouseY = event.clientY - rect.top;
        if (mouseY === 50) {
          wasCenter = true;
        }
        if (wasCenter) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.beginPath();
          ctx.moveTo(0, centerY);
          ctx.quadraticCurveTo(endX / 2, mouseY, endX, centerY);
          ctx.lineWidth = 5;
          ctx.stroke();
        }
      };

      const animate = (mouseY, endY, play) => {
        let currentY = Math.round(mouseY / 5) * 5;
        endY = Math.round(endY / 5) * 5;
        const animateStep = () => {
          if (play) {
            currentY += 5;
          }
          if (!play) {
            currentY -= 5;
          }
          if (currentY === endY) {
            if (currentY < 75) {
              endY = centerY - (currentY - centerY) - 5;
              play = true;
            } else {
              if (currentY > 75) {
                endY = centerY - (currentY - centerY) + 5;
                play = false;
              }
            }
          }

          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.beginPath();
          ctx.moveTo(0, centerY);
          ctx.quadraticCurveTo(endX / 2, currentY, endX, centerY);
          ctx.lineWidth = 5;
          ctx.stroke();

          if ((currentY && endY) === 75) {
            return;
          } else {
            requestAnimationFrame(animateStep);
          }
        };

        requestAnimationFrame(animateStep);
      };
      const handleMouseLeave = (event) => {
        wasCenter = false;
        const rect = canvas.getBoundingClientRect();
        let mouseY = event.clientY - rect.top;
        if (mouseY < 0) {
          mouseY = 50;
        } else {
          if (mouseY > 150) {
            mouseY = 100;
          } else {
            mouseY = event.clientY - rect.top;
          }
        }
        let play;
        const endY = centerY - (mouseY - centerY);
        if (mouseY < 75) {
          play = true;
        }
        if (mouseY > 75) {
          play = false;
        }
        animate(mouseY, endY, play);
      };

      canvas.addEventListener("mousemove", handleMouseMove);
      canvas.addEventListener("mouseleave", handleMouseLeave);
      return () => {
        canvas.addEventListener("mouseleave", handleMouseLeave);
      };
    }, []);

    useEffect(() => {
      const canvas = canvasRef2.current;
      const ctx = canvas.getContext("2d");
      let wasCenter = false;
      const centerY = canvas.height / 2;
      const endX = canvas.width;
      ctx.beginPath();
      ctx.moveTo(0, centerY);
      ctx.lineTo(endX, centerY);
      ctx.lineWidth = 5;
      ctx.strokeStyle = "#CBC9E2";
      ctx.stroke();

      const handleMouseMove = (event) => {
        const rect = canvas.getBoundingClientRect();
        let mouseY;
        mouseY = event.clientY - rect.top;
        if (mouseY === 50) {
          wasCenter = true;
        }
        if (wasCenter) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.beginPath();
          ctx.moveTo(0, centerY);
          ctx.quadraticCurveTo(endX / 2, mouseY, endX, centerY);
          ctx.lineWidth = 5;
          ctx.stroke();
        }
      };

      const animate = (mouseY, endY, play) => {
        let currentY = Math.round(mouseY / 5) * 5;
        endY = Math.round(endY / 5) * 5;
        const animateStep = () => {
          if (play) {
            currentY += 5;
          }
          if (!play) {
            currentY -= 5;
          }
          if (currentY === endY) {
            if (currentY < 75) {
              endY = centerY - (currentY - centerY) - 5;
              play = true;
            } else {
              if (currentY > 75) {
                endY = centerY - (currentY - centerY) + 5;
                play = false;
              }
            }
          }

          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.beginPath();
          ctx.moveTo(0, centerY);
          ctx.quadraticCurveTo(endX / 2, currentY, endX, centerY);
          ctx.lineWidth = 5;
          ctx.stroke();

          if ((currentY && endY) === 75) {
            return;
          } else {
            requestAnimationFrame(animateStep);
          }
        };

        requestAnimationFrame(animateStep);
      };
      const handleMouseLeave = (event) => {
        wasCenter = false;
        const rect = canvas.getBoundingClientRect();
        let mouseY = event.clientY - rect.top;
        if (mouseY < 0) {
          mouseY = 50;
        } else {
          if (mouseY > 150) {
            mouseY = 100;
          } else {
            mouseY = event.clientY - rect.top;
          }
        }
        let play;
        const endY = centerY - (mouseY - centerY);
        if (mouseY < 75) {
          play = true;
        }
        if (mouseY > 75) {
          play = false;
        }
        animate(mouseY, endY, play);
      };

      canvas.addEventListener("mousemove", handleMouseMove);
      canvas.addEventListener("mouseleave", handleMouseLeave);
      return () => {
        canvas.addEventListener("mouseleave", handleMouseLeave);
      };
    }, []);
    useEffect(() => {
      const canvas = canvasRef3.current;
      const ctx = canvas.getContext("2d");
      let wasCenter = false;
      const centerY = canvas.height / 2;
      const endX = canvas.width;
      ctx.beginPath();
      ctx.moveTo(0, centerY);
      ctx.lineTo(endX, centerY);
      ctx.lineWidth = 5;
      ctx.strokeStyle = "#CBC9E2";
      ctx.stroke();

      const handleMouseMove = (event) => {
        const rect = canvas.getBoundingClientRect();
        let mouseY;
        mouseY = event.clientY - rect.top;
        if (mouseY === 50) {
          wasCenter = true;
        }
        if (wasCenter) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.beginPath();
          ctx.moveTo(0, centerY);
          ctx.quadraticCurveTo(endX / 2, mouseY, endX, centerY);
          ctx.lineWidth = 5;
          ctx.stroke();
        }
      };

      const animate = (mouseY, endY, play) => {
        let currentY = Math.round(mouseY / 5) * 5;
        endY = Math.round(endY / 5) * 5;
        const animateStep = () => {
          if (play) {
            currentY += 5;
          }
          if (!play) {
            currentY -= 5;
          }
          if (currentY === endY) {
            if (currentY < 75) {
              endY = centerY - (currentY - centerY) - 5;
              play = true;
            } else {
              if (currentY > 75) {
                endY = centerY - (currentY - centerY) + 5;
                play = false;
              }
            }
          }

          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.beginPath();
          ctx.moveTo(0, centerY);
          ctx.quadraticCurveTo(endX / 2, currentY, endX, centerY);
          ctx.lineWidth = 5;
          ctx.stroke();

          if ((currentY && endY) === 75) {
            return;
          } else {
            requestAnimationFrame(animateStep);
          }
        };

        requestAnimationFrame(animateStep);
      };
      const handleMouseLeave = (event) => {
        wasCenter = false;
        const rect = canvas.getBoundingClientRect();
        let mouseY = event.clientY - rect.top;
        if (mouseY < 0) {
          mouseY = 50;
        } else {
          if (mouseY > 150) {
            mouseY = 100;
          } else {
            mouseY = event.clientY - rect.top;
          }
        }
        let play;
        const endY = centerY - (mouseY - centerY);
        if (mouseY < 75) {
          play = true;
        }
        if (mouseY > 75) {
          play = false;
        }
        animate(mouseY, endY, play);
      };

      canvas.addEventListener("mousemove", handleMouseMove);
      canvas.addEventListener("mouseleave", handleMouseLeave);
      return () => {
        canvas.addEventListener("mouseleave", handleMouseLeave);
      };
    }, []);
    useEffect(() => {
      const canvas = canvasRef4.current;
      const ctx = canvas.getContext("2d");
      let wasCenter = false;
      const centerY = canvas.height / 2;
      const endX = canvas.width;
      ctx.beginPath();
      ctx.moveTo(0, centerY);
      ctx.lineTo(endX, centerY);
      ctx.lineWidth = 5;
      ctx.strokeStyle = "#CBC9E2";
      ctx.stroke();

      const handleMouseMove = (event) => {
        const rect = canvas.getBoundingClientRect();
        let mouseY;
        mouseY = event.clientY - rect.top;
        if (mouseY === 50) {
          wasCenter = true;
        }
        if (wasCenter) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.beginPath();
          ctx.moveTo(0, centerY);
          ctx.quadraticCurveTo(endX / 2, mouseY, endX, centerY);
          ctx.lineWidth = 5;
          ctx.stroke();
        }
      };

      const animate = (mouseY, endY, play) => {
        let currentY = Math.round(mouseY / 5) * 5;
        endY = Math.round(endY / 5) * 5;
        const animateStep = () => {
          if (play) {
            currentY += 5;
          }
          if (!play) {
            currentY -= 5;
          }
          if (currentY === endY) {
            if (currentY < 75) {
              endY = centerY - (currentY - centerY) - 5;
              play = true;
            } else {
              if (currentY > 75) {
                endY = centerY - (currentY - centerY) + 5;
                play = false;
              }
            }
          }

          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.beginPath();
          ctx.moveTo(0, centerY);
          ctx.quadraticCurveTo(endX / 2, currentY, endX, centerY);
          ctx.lineWidth = 5;
          ctx.stroke();

          if ((currentY && endY) === 75) {
            return;
          } else {
            requestAnimationFrame(animateStep);
          }
        };

        requestAnimationFrame(animateStep);
      };
      const handleMouseLeave = (event) => {
        wasCenter = false;
        const rect = canvas.getBoundingClientRect();
        let mouseY = event.clientY - rect.top;
        if (mouseY < 0) {
          mouseY = 50;
        } else {
          if (mouseY > 150) {
            mouseY = 100;
          } else {
            mouseY = event.clientY - rect.top;
          }
        }
        let play;
        const endY = centerY - (mouseY - centerY);
        if (mouseY < 75) {
          play = true;
        }
        if (mouseY > 75) {
          play = false;
        }
        animate(mouseY, endY, play);
      };

      canvas.addEventListener("mousemove", handleMouseMove);
      canvas.addEventListener("mouseleave", handleMouseLeave);
      return () => {
        canvas.addEventListener("mouseleave", handleMouseLeave);
      };
    }, []);
    useEffect(() => {
      const canvas = canvasRef5.current;
      const ctx = canvas.getContext("2d");
      let wasCenter = false;
      const centerY = canvas.height / 2;
      const endX = canvas.width;
      ctx.beginPath();
      ctx.moveTo(0, centerY);
      ctx.lineTo(endX, centerY);
      ctx.lineWidth = 5;
      ctx.strokeStyle = "#CBC9E2";
      ctx.stroke();

      const handleMouseMove = (event) => {
        const rect = canvas.getBoundingClientRect();
        let mouseY;
        mouseY = event.clientY - rect.top;
        if (mouseY === 50) {
          wasCenter = true;
        }
        if (wasCenter) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.beginPath();
          ctx.moveTo(0, centerY);
          ctx.quadraticCurveTo(endX / 2, mouseY, endX, centerY);
          ctx.lineWidth = 5;
          ctx.stroke();
        }
      };

      const animate = (mouseY, endY, play) => {
        let currentY = Math.round(mouseY / 5) * 5;
        endY = Math.round(endY / 5) * 5;
        const animateStep = () => {
          if (play) {
            currentY += 5;
          }
          if (!play) {
            currentY -= 5;
          }
          if (currentY === endY) {
            if (currentY < 75) {
              endY = centerY - (currentY - centerY) - 5;
              play = true;
            } else {
              if (currentY > 75) {
                endY = centerY - (currentY - centerY) + 5;
                play = false;
              }
            }
          }

          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.beginPath();
          ctx.moveTo(0, centerY);
          ctx.quadraticCurveTo(endX / 2, currentY, endX, centerY);
          ctx.lineWidth = 5;
          ctx.stroke();

          if ((currentY && endY) === 75) {
            return;
          } else {
            requestAnimationFrame(animateStep);
          }
        };

        requestAnimationFrame(animateStep);
      };
      const handleMouseLeave = (event) => {
        wasCenter = false;
        const rect = canvas.getBoundingClientRect();
        let mouseY = event.clientY - rect.top;
        if (mouseY < 0) {
          mouseY = 50;
        } else {
          if (mouseY > 150) {
            mouseY = 100;
          } else {
            mouseY = event.clientY - rect.top;
          }
        }
        let play;
        const endY = centerY - (mouseY - centerY);
        if (mouseY < 75) {
          play = true;
        }
        if (mouseY > 75) {
          play = false;
        }
        animate(mouseY, endY, play);
      };

      canvas.addEventListener("mousemove", handleMouseMove);
      canvas.addEventListener("mouseleave", handleMouseLeave);
      return () => {
        canvas.addEventListener("mouseleave", handleMouseLeave);
      };
    }, []);
    useEffect(() => {
      const canvas = canvasRef6.current;
      const ctx = canvas.getContext("2d");
      let wasCenter = false;
      const centerY = canvas.height / 2;
      const endX = canvas.width;
      ctx.beginPath();
      ctx.moveTo(0, centerY);
      ctx.lineTo(endX, centerY);
      ctx.lineWidth = 5;
      ctx.strokeStyle = "#CBC9E2";
      ctx.stroke();

      const handleMouseMove = (event) => {
        const rect = canvas.getBoundingClientRect();
        let mouseY;
        mouseY = event.clientY - rect.top;
        if (mouseY === 50) {
          wasCenter = true;
        }
        if (wasCenter) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.beginPath();
          ctx.moveTo(0, centerY);
          ctx.quadraticCurveTo(endX / 2, mouseY, endX, centerY);
          ctx.lineWidth = 5;
          ctx.stroke();
        }
      };

      const animate = (mouseY, endY, play) => {
        let currentY = Math.round(mouseY / 5) * 5;
        endY = Math.round(endY / 5) * 5;
        const animateStep = () => {
          if (play) {
            currentY += 5;
          }
          if (!play) {
            currentY -= 5;
          }
          if (currentY === endY) {
            if (currentY < 75) {
              endY = centerY - (currentY - centerY) - 5;
              play = true;
            } else {
              if (currentY > 75) {
                endY = centerY - (currentY - centerY) + 5;
                play = false;
              }
            }
          }

          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.beginPath();
          ctx.moveTo(0, centerY);
          ctx.quadraticCurveTo(endX / 2, currentY, endX, centerY);
          ctx.lineWidth = 5;
          ctx.stroke();

          if ((currentY && endY) === 75) {
            return;
          } else {
            requestAnimationFrame(animateStep);
          }
        };

        requestAnimationFrame(animateStep);
      };
      const handleMouseLeave = (event) => {
        wasCenter = false;
        const rect = canvas.getBoundingClientRect();
        let mouseY = event.clientY - rect.top;
        if (mouseY < 0) {
          mouseY = 50;
        } else {
          if (mouseY > 150) {
            mouseY = 100;
          } else {
            mouseY = event.clientY - rect.top;
          }
        }
        let play;
        const endY = centerY - (mouseY - centerY);
        if (mouseY < 75) {
          play = true;
        }
        if (mouseY > 75) {
          play = false;
        }
        animate(mouseY, endY, play);
      };

      canvas.addEventListener("mousemove", handleMouseMove);
      canvas.addEventListener("mouseleave", handleMouseLeave);
      return () => {
        canvas.addEventListener("mouseleave", handleMouseLeave);
      };
    }, []);
    useEffect(() => {
      const canvas = canvasRef7.current;
      const ctx = canvas.getContext("2d");
      let wasCenter = false;
      const centerY = canvas.height / 2;
      const endX = canvas.width;
      ctx.beginPath();
      ctx.moveTo(0, centerY);
      ctx.lineTo(endX, centerY);
      ctx.lineWidth = 5;
      ctx.strokeStyle = "#CBC9E2";
      ctx.stroke();

      const handleMouseMove = (event) => {
        const rect = canvas.getBoundingClientRect();
        let mouseY;
        mouseY = event.clientY - rect.top;
        if (mouseY === 50) {
          wasCenter = true;
        }
        if (wasCenter) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.beginPath();
          ctx.moveTo(0, centerY);
          ctx.quadraticCurveTo(endX / 2, mouseY, endX, centerY);
          ctx.lineWidth = 5;
          ctx.stroke();
        }
      };

      const animate = (mouseY, endY, play) => {
        let currentY = Math.round(mouseY / 5) * 5;
        endY = Math.round(endY / 5) * 5;
        const animateStep = () => {
          if (play) {
            currentY += 5;
          }
          if (!play) {
            currentY -= 5;
          }
          if (currentY === endY) {
            if (currentY < 75) {
              endY = centerY - (currentY - centerY) - 5;
              play = true;
            } else {
              if (currentY > 75) {
                endY = centerY - (currentY - centerY) + 5;
                play = false;
              }
            }
          }

          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.beginPath();
          ctx.moveTo(0, centerY);
          ctx.quadraticCurveTo(endX / 2, currentY, endX, centerY);
          ctx.lineWidth = 5;
          ctx.stroke();

          if ((currentY && endY) === 75) {
            return;
          } else {
            requestAnimationFrame(animateStep);
          }
        };

        requestAnimationFrame(animateStep);
      };
      const handleMouseLeave = (event) => {
        wasCenter = false;
        const rect = canvas.getBoundingClientRect();
        let mouseY = event.clientY - rect.top;
        if (mouseY < 0) {
          mouseY = 50;
        } else {
          if (mouseY > 150) {
            mouseY = 100;
          } else {
            mouseY = event.clientY - rect.top;
          }
        }
        let play;
        const endY = centerY - (mouseY - centerY);
        if (mouseY < 75) {
          play = true;
        }
        if (mouseY > 75) {
          play = false;
        }
        animate(mouseY, endY, play);
      };

      canvas.addEventListener("mousemove", handleMouseMove);
      canvas.addEventListener("mouseleave", handleMouseLeave);
      return () => {
        canvas.addEventListener("mouseleave", handleMouseLeave);
      };
    }, []);
    // useEffect(() => {
    //   const canvas = canvasRef8.current;
    //   const ctx = canvas.getContext("2d");
    //   let wasCenter = false;
    //   const centerY = canvas.height / 2;
    //   const endX = canvas.width;
    //   ctx.beginPath();
    //   ctx.moveTo(0, centerY);
    //   ctx.lineTo(endX, centerY);
    //   ctx.lineWidth = 5;
    //   ctx.strokeStyle = "#CBC9E2";
    //   ctx.stroke();

    //   const handleMouseMove = (event) => {
    //     const rect = canvas.getBoundingClientRect();
    //     let mouseY;
    //     mouseY = event.clientY - rect.top;
    //     if (mouseY === 50) {
    //       wasCenter = true;
    //     }
    //     if (wasCenter) {
    //       ctx.clearRect(0, 0, canvas.width, canvas.height);
    //       ctx.beginPath();
    //       ctx.moveTo(0, centerY);
    //       ctx.quadraticCurveTo(endX / 2, mouseY, endX, centerY);
    //       ctx.lineWidth = 5;
    //       ctx.stroke();
    //     }
    //   };

    //   const animate = (mouseY, endY, play) => {
    //     let currentY = Math.round(mouseY / 5) * 5;
    //     endY = Math.round(endY / 5) * 5;
    //     const animateStep = () => {
    //       if (play) {
    //         currentY += 5;
    //       }
    //       if (!play) {
    //         currentY -= 5;
    //       }
    //       if (currentY === endY) {
    //         if (currentY < 75) {
    //           endY = centerY - (currentY - centerY) - 5;
    //           play = true;
    //         } else {
    //           if (currentY > 75) {
    //             endY = centerY - (currentY - centerY) + 5;
    //             play = false;
    //           }
    //         }
    //       }

    //       ctx.clearRect(0, 0, canvas.width, canvas.height);
    //       ctx.beginPath();
    //       ctx.moveTo(0, centerY);
    //       ctx.quadraticCurveTo(endX / 2, currentY, endX, centerY);
    //       ctx.lineWidth = 5;
    //       ctx.stroke();

    //       if ((currentY && endY) === 75) {
    //         return;
    //       } else {
    //         requestAnimationFrame(animateStep);
    //       }
    //     };

    //     requestAnimationFrame(animateStep);
    //   };
    //   const handleMouseLeave = (event) => {
    //     wasCenter = false;
    //     const rect = canvas.getBoundingClientRect();
    //     let mouseY = event.clientY - rect.top;
    //     if (mouseY < 0) {
    //       mouseY = 50;
    //     } else {
    //       if (mouseY > 150) {
    //         mouseY = 100;
    //       } else {
    //         mouseY = event.clientY - rect.top;
    //       }
    //     }
    //     let play;
    //     const endY = centerY - (mouseY - centerY);
    //     if (mouseY < 75) {
    //       play = true;
    //     }
    //     if (mouseY > 75) {
    //       play = false;
    //     }
    //     animate(mouseY, endY, play);
    //   };

    //   canvas.addEventListener("mousemove", handleMouseMove);
    //   canvas.addEventListener("mouseleave", handleMouseLeave);
    //   return () => {
    //     canvas.addEventListener("mouseleave", handleMouseLeave);
    //   };
    // }, []);
    // useEffect(() => {
    //   const canvas = canvasRef9.current;
    //   const ctx = canvas.getContext("2d");
    //   let wasCenter = false;
    //   const centerY = canvas.height / 2;
    //   const endX = canvas.width;
    //   ctx.beginPath();
    //   ctx.moveTo(0, centerY);
    //   ctx.lineTo(endX, centerY);
    //   ctx.lineWidth = 5;
    //   ctx.strokeStyle = "#CBC9E2";
    //   ctx.stroke();

    //   const handleMouseMove = (event) => {
    //     const rect = canvas.getBoundingClientRect();
    //     let mouseY;
    //     mouseY = event.clientY - rect.top;
    //     if (mouseY === 50) {
    //       wasCenter = true;
    //     }
    //     if (wasCenter) {
    //       ctx.clearRect(0, 0, canvas.width, canvas.height);
    //       ctx.beginPath();
    //       ctx.moveTo(0, centerY);
    //       ctx.quadraticCurveTo(endX / 2, mouseY, endX, centerY);
    //       ctx.lineWidth = 5;
    //       ctx.stroke();
    //     }
    //   };

    //   const animate = (mouseY, endY, play) => {
    //     let currentY = Math.round(mouseY / 5) * 5;
    //     endY = Math.round(endY / 5) * 5;
    //     const animateStep = () => {
    //       if (play) {
    //         currentY += 5;
    //       }
    //       if (!play) {
    //         currentY -= 5;
    //       }
    //       if (currentY === endY) {
    //         if (currentY < 75) {
    //           endY = centerY - (currentY - centerY) - 5;
    //           play = true;
    //         } else {
    //           if (currentY > 75) {
    //             endY = centerY - (currentY - centerY) + 5;
    //             play = false;
    //           }
    //         }
    //       }

    //       ctx.clearRect(0, 0, canvas.width, canvas.height);
    //       ctx.beginPath();
    //       ctx.moveTo(0, centerY);
    //       ctx.quadraticCurveTo(endX / 2, currentY, endX, centerY);
    //       ctx.lineWidth = 5;
    //       ctx.stroke();

    //       if ((currentY && endY) === 75) {
    //         return;
    //       } else {
    //         requestAnimationFrame(animateStep);
    //       }
    //     };

    //     requestAnimationFrame(animateStep);
    //   };
    //   const handleMouseLeave = (event) => {
    //     wasCenter = false;
    //     const rect = canvas.getBoundingClientRect();
    //     let mouseY = event.clientY - rect.top;
    //     if (mouseY < 0) {
    //       mouseY = 50;
    //     } else {
    //       if (mouseY > 150) {
    //         mouseY = 100;
    //       } else {
    //         mouseY = event.clientY - rect.top;
    //       }
    //     }
    //     let play;
    //     const endY = centerY - (mouseY - centerY);
    //     if (mouseY < 75) {
    //       play = true;
    //     }
    //     if (mouseY > 75) {
    //       play = false;
    //     }
    //     animate(mouseY, endY, play);
    //   };

    //   canvas.addEventListener("mousemove", handleMouseMove);
    //   canvas.addEventListener("mouseleave", handleMouseLeave);
    //   return () => {
    //     canvas.addEventListener("mouseleave", handleMouseLeave);
    //   };
    // }, []);
    // useEffect(() => {
    //   const canvas = canvasRef10.current;
    //   const ctx = canvas.getContext("2d");
    //   let wasCenter = false;
    //   const centerY = canvas.height / 2;
    //   const endX = canvas.width;
    //   ctx.beginPath();
    //   ctx.moveTo(0, centerY);
    //   ctx.lineTo(endX, centerY);
    //   ctx.lineWidth = 5;
    //   ctx.strokeStyle = "#CBC9E2";
    //   ctx.stroke();

    //   const handleMouseMove = (event) => {
    //     const rect = canvas.getBoundingClientRect();
    //     let mouseY;
    //     mouseY = event.clientY - rect.top;
    //     if (mouseY === 50) {
    //       wasCenter = true;
    //     }
    //     if (wasCenter) {
    //       ctx.clearRect(0, 0, canvas.width, canvas.height);
    //       ctx.beginPath();
    //       ctx.moveTo(0, centerY);
    //       ctx.quadraticCurveTo(endX / 2, mouseY, endX, centerY);
    //       ctx.lineWidth = 5;
    //       ctx.stroke();
    //     }
    //   };

    //   const animate = (mouseY, endY, play) => {
    //     let currentY = Math.round(mouseY / 5) * 5;
    //     endY = Math.round(endY / 5) * 5;
    //     const animateStep = () => {
    //       if (play) {
    //         currentY += 5;
    //       }
    //       if (!play) {
    //         currentY -= 5;
    //       }
    //       if (currentY === endY) {
    //         if (currentY < 75) {
    //           endY = centerY - (currentY - centerY) - 5;
    //           play = true;
    //         } else {
    //           if (currentY > 75) {
    //             endY = centerY - (currentY - centerY) + 5;
    //             play = false;
    //           }
    //         }
    //       }

    //       ctx.clearRect(0, 0, canvas.width, canvas.height);
    //       ctx.beginPath();
    //       ctx.moveTo(0, centerY);
    //       ctx.quadraticCurveTo(endX / 2, currentY, endX, centerY);
    //       ctx.lineWidth = 5;
    //       ctx.stroke();

    //       if ((currentY && endY) === 75) {
    //         return;
    //       } else {
    //         requestAnimationFrame(animateStep);
    //       }
    //     };

    //     requestAnimationFrame(animateStep);
    //   };
    //   const handleMouseLeave = (event) => {
    //     wasCenter = false;
    //     const rect = canvas.getBoundingClientRect();
    //     let mouseY = event.clientY - rect.top;
    //     if (mouseY < 0) {
    //       mouseY = 50;
    //     } else {
    //       if (mouseY > 150) {
    //         mouseY = 100;
    //       } else {
    //         mouseY = event.clientY - rect.top;
    //       }
    //     }
    //     let play;
    //     const endY = centerY - (mouseY - centerY);
    //     if (mouseY < 75) {
    //       play = true;
    //     }
    //     if (mouseY > 75) {
    //       play = false;
    //     }
    //     animate(mouseY, endY, play);
    //   };

    //   canvas.addEventListener("mousemove", handleMouseMove);
    //   canvas.addEventListener("mouseleave", handleMouseLeave);
    //   return () => {
    //     canvas.addEventListener("mouseleave", handleMouseLeave);
    //   };
    // }, []);
    // useEffect(() => {
    //   const canvas = canvasRef11.current;
    //   const ctx = canvas.getContext("2d");
    //   let wasCenter = false;
    //   const centerY = canvas.height / 2;
    //   const endX = canvas.width;
    //   ctx.beginPath();
    //   ctx.moveTo(0, centerY);
    //   ctx.lineTo(endX, centerY);
    //   ctx.lineWidth = 5;
    //   ctx.strokeStyle = "#CBC9E2";
    //   ctx.stroke();

    //   const handleMouseMove = (event) => {
    //     const rect = canvas.getBoundingClientRect();
    //     let mouseY;
    //     mouseY = event.clientY - rect.top;
    //     if (mouseY === 50) {
    //       wasCenter = true;
    //     }
    //     if (wasCenter) {
    //       ctx.clearRect(0, 0, canvas.width, canvas.height);
    //       ctx.beginPath();
    //       ctx.moveTo(0, centerY);
    //       ctx.quadraticCurveTo(endX / 2, mouseY, endX, centerY);
    //       ctx.lineWidth = 5;
    //       ctx.stroke();
    //     }
    //   };

    //   const animate = (mouseY, endY, play) => {
    //     let currentY = Math.round(mouseY / 5) * 5;
    //     endY = Math.round(endY / 5) * 5;
    //     const animateStep = () => {
    //       if (play) {
    //         currentY += 5;
    //       }
    //       if (!play) {
    //         currentY -= 5;
    //       }
    //       if (currentY === endY) {
    //         if (currentY < 75) {
    //           endY = centerY - (currentY - centerY) - 5;
    //           play = true;
    //         } else {
    //           if (currentY > 75) {
    //             endY = centerY - (currentY - centerY) + 5;
    //             play = false;
    //           }
    //         }
    //       }

    //       ctx.clearRect(0, 0, canvas.width, canvas.height);
    //       ctx.beginPath();
    //       ctx.moveTo(0, centerY);
    //       ctx.quadraticCurveTo(endX / 2, currentY, endX, centerY);
    //       ctx.lineWidth = 5;
    //       ctx.stroke();

    //       if ((currentY && endY) === 75) {
    //         return;
    //       } else {
    //         requestAnimationFrame(animateStep);
    //       }
    //     };

    //     requestAnimationFrame(animateStep);
    //   };
    //   const handleMouseLeave = (event) => {
    //     wasCenter = false;
    //     const rect = canvas.getBoundingClientRect();
    //     let mouseY = event.clientY - rect.top;
    //     if (mouseY < 0) {
    //       mouseY = 50;
    //     } else {
    //       if (mouseY > 150) {
    //         mouseY = 100;
    //       } else {
    //         mouseY = event.clientY - rect.top;
    //       }
    //     }
    //     let play;
    //     const endY = centerY - (mouseY - centerY);
    //     if (mouseY < 75) {
    //       play = true;
    //     }
    //     if (mouseY > 75) {
    //       play = false;
    //     }
    //     animate(mouseY, endY, play);
    //   };

    //   canvas.addEventListener("mousemove", handleMouseMove);
    //   canvas.addEventListener("mouseleave", handleMouseLeave);
    //   return () => {
    //     canvas.addEventListener("mouseleave", handleMouseLeave);
    //   };
    // }, []);
    // useEffect(() => {
    //   const canvas = canvasRef12.current;
    //   const ctx = canvas.getContext("2d");
    //   let wasCenter = false;
    //   const centerY = canvas.height / 2;
    //   const endX = canvas.width;
    //   ctx.beginPath();
    //   ctx.moveTo(0, centerY);
    //   ctx.lineTo(endX, centerY);
    //   ctx.lineWidth = 5;
    //   ctx.strokeStyle = "#CBC9E2";
    //   ctx.stroke();

    //   const handleMouseMove = (event) => {
    //     const rect = canvas.getBoundingClientRect();
    //     let mouseY;
    //     mouseY = event.clientY - rect.top;
    //     if (mouseY === 50) {
    //       wasCenter = true;
    //     }
    //     if (wasCenter) {
    //       ctx.clearRect(0, 0, canvas.width, canvas.height);
    //       ctx.beginPath();
    //       ctx.moveTo(0, centerY);
    //       ctx.quadraticCurveTo(endX / 2, mouseY, endX, centerY);
    //       ctx.lineWidth = 5;
    //       ctx.stroke();
    //     }
    //   };

    //   const animate = (mouseY, endY, play) => {
    //     let currentY = Math.round(mouseY / 5) * 5;
    //     endY = Math.round(endY / 5) * 5;
    //     const animateStep = () => {
    //       if (play) {
    //         currentY += 5;
    //       }
    //       if (!play) {
    //         currentY -= 5;
    //       }
    //       if (currentY === endY) {
    //         if (currentY < 75) {
    //           endY = centerY - (currentY - centerY) - 5;
    //           play = true;
    //         } else {
    //           if (currentY > 75) {
    //             endY = centerY - (currentY - centerY) + 5;
    //             play = false;
    //           }
    //         }
    //       }

    //       ctx.clearRect(0, 0, canvas.width, canvas.height);
    //       ctx.beginPath();
    //       ctx.moveTo(0, centerY);
    //       ctx.quadraticCurveTo(endX / 2, currentY, endX, centerY);
    //       ctx.lineWidth = 5;
    //       ctx.stroke();

    //       if ((currentY && endY) === 75) {
    //         return;
    //       } else {
    //         requestAnimationFrame(animateStep);
    //       }
    //     };

    //     requestAnimationFrame(animateStep);
    //   };
    //   const handleMouseLeave = (event) => {
    //     wasCenter = false;
    //     const rect = canvas.getBoundingClientRect();
    //     let mouseY = event.clientY - rect.top;
    //     if (mouseY < 0) {
    //       mouseY = 50;
    //     } else {
    //       if (mouseY > 150) {
    //         mouseY = 100;
    //       } else {
    //         mouseY = event.clientY - rect.top;
    //       }
    //     }
    //     let play;
    //     const endY = centerY - (mouseY - centerY);
    //     if (mouseY < 75) {
    //       play = true;
    //     }
    //     if (mouseY > 75) {
    //       play = false;
    //     }
    //     animate(mouseY, endY, play);
    //   };

    //   canvas.addEventListener("mousemove", handleMouseMove);
    //   canvas.addEventListener("mouseleave", handleMouseLeave);
    //   return () => {
    //     canvas.addEventListener("mouseleave", handleMouseLeave);
    //   };
    // }, []);

    const [scrollDirection, setScrollDirection] = useState("");

    let touchStartY = 0;
    let touchEndY = 0;
    const handleTouchStart = (event) => {
      touchStartY = event.touches[0]?.clientY;
    };
    const handleTouchMove = (event) => {
      touchEndY = event.touches[0].clientY;
    };

    const handleTouchEnd = (event) => {
      if (touchEndY > touchStartY) {
        setScrollDirection("down");
        onMouseWheelUp();
      } else if (touchEndY < touchStartY) {
        setScrollDirection("up");
        onMouseWheelDown();
      } else {
        setScrollDirection("none");
      }
    };

    return (
      <section
        className={
          currentPage === "/friends" ? style.friendsPage : style.friendsPageHide
        }
        onWheel={onMouseWheel}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        // ref={friendsPageRef}
      >
        <div
          className={
            isMobile
              ? style.friendsPageWrapper
              : currentPage === "/friends"
              ? style.friendsPageWrapper
              : style.friendsPageWrapperHide
          }
          ref={friendsRef}
        >
          {/* <button onClick={p} className={style.pp}>
          gdsfgsgfd
        </button> */}
          <div className={style.friendsPageTextWrapper}>
            {/* <p className={style.friendsPageTitle}>our team</p> */}
            <h2 className={style.friendsPageSubtitle}>let`s become friends</h2>
          </div>
          {/* <img src={AlexIMG} alt="" className={style.alexIMG} />
          <img src={DenysIMG} alt="" className={style.denysIMG} />
          <img src={DianaIMG} alt="" className={style.dianaIMG} /> */}
          {/* <div className={style.blot1}></div>
          <div className={style.blot2}></div>
          <div className={style.blot3}></div>
          <div className={style.blot4}></div>
          <div className={style.blot5}></div>
          <div className={style.blot6}></div> */}
          <img src={blot1} className={style.blot1} alt="" />
          <img src={blot2} className={style.blot2} alt="" />
          <img src={blot3} className={style.blot3} alt="" />
          <img src={blot4} className={style.blot4} alt="" />
          <img src={blot5} className={style.blot5} alt="" />
          <img src={blot6} className={style.blot6} alt="" />
        </div>
        <div
          className={
            isMobile
              ? null
              : currentPage === "/friends"
              ? setFriendsTransitionStyle()
              : setFriendsTransitionStyle()
          }
        >
          <canvas ref={canvasRef} className={style.footerCanvas} />
          <canvas ref={canvasRef2} className={style.footerCanvas2} />
          <canvas ref={canvasRef3} className={style.footerCanvas3} />
          <canvas ref={canvasRef4} className={style.footerCanvas4} />
          <canvas ref={canvasRef5} className={style.footerCanvas5} />
          <canvas ref={canvasRef6} className={style.footerCanvas6} />
          <canvas ref={canvasRef7} className={style.footerCanvas7} />
          {/* <canvas ref={canvasRef8} className={style.footerCanvas8} />
          <canvas ref={canvasRef9} className={style.footerCanvas9} />
          <canvas ref={canvasRef10} className={style.footerCanvas10} />
          <canvas ref={canvasRef11} className={style.footerCanvas11} />
          <canvas ref={canvasRef12} className={style.footerCanvas12} /> */}
        </div>
      </section>
    );
  }
);

export default FriendsPage;
