import React, { useState, useEffect } from "react";
import Header from "./Components/Header/Header";
import Main from "./Components/Main/Main";
import Footer from "./Components/Footer/Footer";
import style from "./App.module.scss";
import CsSign from "./assets/CsSign";
import CsSignB from "./assets/CsSignB";
import Dribble from "./assets/Dribble";
import Instagram from "./assets/Instagram";
import LinkedIn from "./assets/LinkedIn";
import { ReactComponent as TopLeftBgCircle } from "./Icons/Circles/topLeftBgCircle.svg";
import { ReactComponent as BottomLeftBgCircle } from "./Icons/Circles/bottomLeftBgCircle.svg";
import { ReactComponent as BottomRightBgCircle } from "./Icons/Circles/bottomRightBgCircle.svg";
import { ReactComponent as TopLeftDesktopBgCircle } from "./Icons/Circles/topLeftDesktopBgCircle.svg";
import { ReactComponent as BottomLeftDesktopBgCircle } from "./Icons/Circles/bottomLeftDesktopBgCircle.svg";
import { ReactComponent as BottomRightDesktopBgCircle } from "./Icons/Circles/bottomRightDesktopBgCircle.svg";
import ServicesBlock from "./Components/Main/ServicesBlock/ServicesBlock";
import FriendsPage from "./Components/Main/FriendsPage/FriendsPage";
import { Routes, Route } from "react-router-dom";
import PortfolioProjects from "./Components/Main/PortfolioProjects/PortfolioProjects";
import ContactUs from "./Components/Main/ContactUs/ContactUs";
import { useNavigate } from "react-router-dom";
import TitlePage from "./Components/Main/TitlePage/TitlePage";
import ContactUsButton from "./Components/UI/ContactUsButton/ContactUsButton";
import { useRef } from "react";
import { InView, useInView } from "react-intersection-observer";

const App = () => {
  const [isMobile, setIsMobile] = useState();
  const [isAppContactUs, setIsAppContactUs] = useState(true);
  const [isMainContactUs, setIsMainContactUs] = useState(true);
  const [isAppPortfolioProjects, setIsAppPortfolioProjects] = useState(false);
  const [isCongratulations, setCongratulations] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [currentPage, setCurrentPage] = useState("/");
  const scrollRef = useRef();
  const [preventServiceRoute, setPreventServiceRoute] = useState();
  const [portfolioTransition, setPortfolioTransition] =
    useState("fromServices");
  const [violetBackground, setVioletBackground] = useState(false);
  const [backgroundTimeout, setBackgroundTimeout] = useState(false);
  const [portfolioSCroll, setPortfolioSCroll] = useState();
  const [friendsTransition, setFriendsTransition] = useState("fromPortfolio");
  const [isFriendsChange, setIsFriendsChange] = useState(false);
  const [isContactsChange, setIsContactsChange] = useState(false);
  const [isMobileContent, setIsMobileContent] = useState(true);
  const [isMobileContentFromSolutio, setIsMobileContentFromSolutio] =
    useState(false);
  const [isFromContactUs, setIsFromContactUs] = useState(false);
  const [lastMobileContent, setLastMobileContent] = useState();
  const [
    isFromPortfolioProjectsToContactUs,
    setIsFromPortfolioProjectsToContactUs,
  ] = useState();
  // useEffect(() => {
  //   window.history.scrollRestoration = "manual";
  // }, []);

  const { ref: refFriendsPage, inView } = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (window.screen.width <= 982) {
      setIsMobile((prev) => (prev = true));
    } else {
      setIsMobile((prev) => (prev = false));
    }
  }, []);

  const { ref: titleRef, inView: listView } = useInView({
    threshold: 1,
  });
  const ref = useRef();
  const refMain = useRef();
  useEffect(() => {
    if (isAppContactUs) {
    } else {
      setIsMainContactUs((prev) => (prev = false));
    }
  }, [isAppContactUs]);

  const navigate = useNavigate();

  if (isCongratulations) {
    setTimeout(() => {
      navigate("/");
      setCongratulations(false);
    }, 5000);
  }

  useEffect(() => {
    if (currentPage !== "/") {
      setTimeout(() => {
        if (ref.current) {
          ref.current.style.display = "none";
        }
      }, 1000);
    } else {
      setTimeout(() => {
        if (ref.current) {
          ref.current.style.display = "block";
        }
      }, 1000);
    }
  });

  const refServices = useRef();

  // const setServicesDisplay = () => {
  //   if (currentPage !== "/services") {
  //     if (refServices.current) {
  //       refServices.current.className = style.serviceWrapperHide;
  //     }
  //   } else {
  //     setTimeout(() => {
  //       if (refServices.current) {
  //         refServices.current.className = style.serviceWrapper;
  //       }
  //     }, 1000);
  //   }
  // };

  useEffect(() => {
    setTimeout(() => {
      if (currentPage === "/") {
        navigate("/");
      }
    }, 1000);
    setTimeout(() => {
      if (currentPage === "/services") {
        navigate("/services");
      }
    }, 1000);
    setTimeout(() => {
      if (currentPage === "/portfolio") {
        navigate("/portfolio");
        // setVioletBackground(false);
      }
    }, 1000);
    setTimeout(() => {
      if (currentPage === "/friends") {
        navigate("/friends");
      }
    }, 500);
    setTimeout(() => {
      if (currentPage === "/contacts") {
        navigate("/contacts");
      }
    }, 500);

    // isMobile ? :
  }, [currentPage]);

  const setPortfolioTransitionStyle = () => {
    if (!isMobile) {
      if (portfolioTransition === "fromServices") {
        return style.mainPageWrapper;
      }
      if (portfolioTransition === "toServices") {
        return style.mainPageWrapperHide;
      }
      if (portfolioTransition === "fromContact") {
        return style.mainPageWrapperFromContact;
      }
      if (portfolioTransition === "toContact") {
        clearInterval(changeBackground);
        if (!violetBackground) {
          setVioletBackground(true);
        }
        return style.mainPageWrapperToContact;
      }
    }
  };

  const setFriendsTransitionStyle = () => {
    if (portfolioTransition === "fromPortfolio") {
      return style.friendsPageWrapper;
    }
    if (portfolioTransition === "toPortfolio") {
      return style.friendsPageWrapperHide;
    }
    if (portfolioTransition === "fromContact") {
      return style.friendsPageWrapperFromContact;
    }
    if (portfolioTransition === "toContact") {
      return style.friendsPageWrapperToContact;
    }
  };

  const changeBackground = () => {
    clearTimeout(backgroundTimeout);
    const timeout = setTimeout(() => {
      setTimeout(() => {
        setVioletBackground(false);
      }, 2000);
    }, 100);
    setBackgroundTimeout(timeout);
  };
  useEffect(() => {
    if (portfolioTransition === "fromContact") {
      if (!isMobile) {
        changeBackground();
      }
    }
  }, [portfolioTransition]);

  const onMouseWheelDown = () => {
    setCurrentPage("/services");
    setIsMobileContent(true);
    setIsMobileContentFromSolutio(true);
  };

  const onMouseWheelUp = () => {};

  const onMouseWheel = (e) => {
    window.event.deltaY > 0 ? onMouseWheelDown() : onMouseWheelUp();
  };

  const [scrollDirection, setScrollDirection] = useState("up");

  let touchStartY = 0;
  let touchEndY = 0;
  const handleTouchStart = (event) => {
    touchStartY = event.touches[0]?.clientY;
  };
  const handleTouchMove = (event) => {
    touchEndY = event.touches[0].clientY;
  };

  const handleTouchEnd = (event) => {
    if (touchEndY > touchStartY) {
      // setScrollDirection("down");
    } else if (touchEndY < touchStartY) {
      // setScrollDirection("up");
      setTimeout(() => {
        onMouseWheelDown();
      }, 100);
    } else {
      // setScrollDirection("none");
    }
  };

  const [scrollStart, setScrollStart] = useState();
  const [scrollEnd, setScrollEnd] = useState();
  const mobailScrollDirection = () => {};
  let touchStartYMobailScrollDirection;
  let touchMoveYMobailScrollDirection;
  const handleTouchStartMobailScrollDirection = (event) => {
    setScrollStart(event.touches[0]?.clientY);
  };
  const handleTouchMoveMobailScrollDirection = (event) => {
    // touchStartYMobailScrollDirection = event.touches[0]?.clientY;
    setScrollEnd(event.touches[0]?.clientY);

    if (scrollStart < scrollEnd) {
      setScrollDirection("up");
    } else {
      if (100 < scrollStart - scrollEnd) {
        setScrollDirection("down");
      }
    }
    // setScrollDirection(touchEndY);
  };
  // const handleTouchEndMobailScrollDirection = () => {
  //   console.log("ENDY" + scrollDirection);
  //   if (window.scrollY === 0) {
  //     if (scrollDirection > touchStartYMobailScrollDirection) {
  //       console.log("YES");
  //     }
  //   }
  //   if (scrollDirection < touchStartY) {
  //   }
  // };
  useEffect(() => {
    if (currentPage === "/") {
      document.body.style.overflow = "hidden";
    } else {
      setTimeout(() => {
        document.body.style.overflow = "visible";
      }, 1250);
    }
  }, [currentPage]);

  // useEffect(() => {
  //   if (isMobileContent) {
  //     window.scrollTo(0, window.scrollY + 1);
  //   }
  // }, [isMobileContent]);

  // useEffect(() => {
  //   if (isFromContactUs) {
  //     window.scrollTo(0, lastMobileContent);
  //     setTimeout(() => {
  //       setIsFromContactUs(false);
  //     }, 500);
  //   }
  // }, [isFromContactUs]);

  return (
    <div
      className={style.app}
      style={
        isMobile
          ? null
          : violetBackground
          ? { backgroundColor: "#7d77b7" }
          : null
      }
    >
      <div className={style.appWrapper}>
        {isMobile ? (
          scrollDirection === "down" ? null : (
            <div>
              {isAppContactUs && (
                <div
                  className={isOpen ? style.openMenuButton : style.menuButton}
                  onClick={() => {
                    setIsFirstTime(false);
                    setIsOpen(!isOpen);
                  }}
                >
                  <div
                    className={
                      isOpen
                        ? style.openModalBurgerButton
                        : style.modalBurgerButton
                    }
                  ></div>
                </div>
              )}
            </div>
          )
        ) : (
          isAppContactUs && (
            <div
              className={isOpen ? style.openMenuButton : style.menuButton}
              onClick={() => {
                setIsFirstTime(false);
                setIsOpen(!isOpen);
              }}
            >
              <div
                className={
                  isOpen ? style.openModalBurgerButton : style.modalBurgerButton
                }
              ></div>
            </div>
          )
        )}
        <div
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className={
            isOpen
              ? style.openBackgroundMenu
              : isFirstTime
              ? null
              : style.backgroundMenu
          }
        ></div>
        {isAppContactUs && (
          <div
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className={
              isOpen
                ? style.modalWindowWrapperOpen
                : isFirstTime
                ? style.modalWindowWrapperHide
                : style.modalWindowWrapper
            }
          >
            <nav className={style.openMenuNavWrapper}>
              <ul className={isOpen ? style.openMenuNav : style.menuNav}>
                <li className={isOpen ? style.openNavItem : style.navItem}>
                  <a
                    className={style.navItemLink}
                    onClick={() => {
                      setIsAppContactUs(true);
                      setIsAppPortfolioProjects(false);
                      setIsMobileContentFromSolutio(false);
                      setCurrentPage("/services");
                      if (isMobile) {
                        if (currentPage === "/") {
                          setCurrentPage("/services");
                          setIsAppContactUs(true);
                          setIsAppPortfolioProjects(false);
                          setIsMobileContentFromSolutio(false);
                          setTimeout(() => {
                            // const element = document.getElementById("services");
                            // element.scrollIntoView({ behavior: "auto" });
                          }, 100);
                        } else {
                          // setIsMobileContentFromSolutio(false);
                          setTimeout(() => {
                            const element = document.getElementById("services");
                            element.scrollIntoView({ behavior: "auto" });
                          }, 250);
                        }
                      }
                    }}
                  >
                    Services
                  </a>
                </li>
                <li className={isOpen ? style.openNavItem : style.navItem}>
                  <a
                    className={style.navItemLink}
                    onClick={() => {
                      setPortfolioTransition("fromServices");
                      setIsAppContactUs(true);
                      setIsAppPortfolioProjects(false);
                      setIsMobileContentFromSolutio(false);
                      setCurrentPage("/portfolio");
                      setVioletBackground(false);
                      if (isMobile) {
                        if (currentPage === "/") {
                          setCurrentPage("/portfolio");
                          setIsAppContactUs(true);
                          setIsAppPortfolioProjects(false);
                          setIsMobileContentFromSolutio(false);
                          // setTimeout(() => {
                          //   const element = document.getElementById("Cases");
                          //   element.scrollIntoView({ behavior: "auto" });
                          // }, 100);
                        } else {
                          setTimeout(() => {
                            const element = document.getElementById("Cases");
                            element.scrollIntoView({ behavior: "auto" });
                          }, 250);
                        }
                      }
                    }}
                  >
                    Cases
                  </a>
                </li>
                <li className={isOpen ? style.openNavItem : style.navItem}>
                  <a
                    className={style.navItemLink}
                    onClick={() => {
                      setCurrentPage("/contacts");
                      setIsAppContactUs(true);
                      setIsAppPortfolioProjects(false);
                      setIsMobileContentFromSolutio(false);
                      setVioletBackground(true);
                      if (isMobile) {
                        if (currentPage === "/") {
                          setCurrentPage("/contacts");
                          setIsAppContactUs(true);
                          setIsAppPortfolioProjects(false);
                          setIsMobileContentFromSolutio(false);
                          // setTimeout(() => {
                          //   const element =
                          //     document.getElementById("footerPageWrapper");
                          //   element.scrollIntoView({ behavior: "auto" });
                          // }, 100);
                        } else {
                          setTimeout(() => {
                            const element =
                              document.getElementById("footerPageWrapper");
                            element.scrollIntoView({ behavior: "auto" });
                          }, 250);
                        }
                      }
                    }}
                  >
                    Contact
                  </a>
                </li>
                <li>
                  <div
                    className={
                      isOpen ? style.openNavSocialLinks : style.navSocialLinks
                    }
                  >
                    <a
                      href="https://www.linkedin.com/company/solutio-studio/about/"
                      target="_blank"
                    >
                      <LinkedIn />
                    </a>
                    <a
                      href="https://dribbble.com/solutiostudio"
                      target="_blank"
                    >
                      <Dribble />
                    </a>
                    <a
                      href="https://instagram.com/solutio_studio?igshid=NTc4MTIwNjQ2YQ=="
                      target="_blank"
                    >
                      <Instagram />
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        )}
        {isAppContactUs &&
          (!isOpen
            ? !isMobile
              ? !isAppPortfolioProjects && (
                  <ContactUsButton className={style.contactUsButton} />
                )
              : null
            : null)}
        <Header
          setIsAppContactUs={setIsAppContactUs}
          setCurrentPage={setCurrentPage}
          setVioletBackground={setVioletBackground}
          setPreventServiceRoute={setPreventServiceRoute}
          setPortfolioTransition={setPortfolioTransition}
          setIsOpen={setIsOpen}
        />
        {isAppContactUs && (
          <div
            className={
              currentPage === "/"
                ? style.titleHeaderBoxWrapper
                : style.titleHeaderBoxWrapperHide
            }
            ref={ref}
            onWheel={onMouseWheel}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div className={style.fixDiv}></div>
            <TopLeftBgCircle
              alt="TopLeftBgCircle"
              className={
                currentPage === "/"
                  ? style.topLeftBgCircleVisited
                  : style.topLeftBgCircleVisitedOff
              }
            />
            {/* <div className={style.topLeftBgFullCircle}></div> */}
            {isAppContactUs && (
              <div>
                <BottomLeftBgCircle
                  alt="BottomLeftBgCircle"
                  className={
                    currentPage === "/"
                      ? style.bottomLeftVisitedBgCircle
                      : style.bottomLeftVisitedBgCircleOff
                  }
                />
                <div
                  className={
                    currentPage === "/"
                      ? style.topRightBgCircleVisited
                      : style.topRightBgCircleVisitedOff
                  }
                ></div>
                <BottomRightBgCircle
                  alt="BottomLeftBgCircle"
                  className={
                    currentPage === "/"
                      ? style.bottomRightBgCircle
                      : style.bottomRightBgCircleOff
                  }
                />
                <TopLeftDesktopBgCircle
                  alt="TopLeftDesktopBgCircle"
                  className={
                    currentPage === "/"
                      ? style.topLeftDesktopVisitedBgCircle
                      : style.topLeftDesktopVisitedBgCircleOff
                  }
                />
                <BottomLeftDesktopBgCircle
                  alt="BottomLeftDesktopBgCircle"
                  className={
                    currentPage === "/"
                      ? style.BottomLeftDesktopVisitedBgCircle
                      : style.BottomLeftDesktopVisitedBgCircleOff
                  }
                />
                <BottomRightDesktopBgCircle
                  alt="BottomRightDesktopBgCircle"
                  className={
                    currentPage === "/"
                      ? style.BottomRightDesktopBgCircle
                      : style.BottomRightDesktopBgCircleOff
                  }
                />
              </div>
            )}
            {isAppContactUs && (
              <div className={style.titlePage} ref={titleRef}>
                <TitlePage />
              </div>
            )}
          </div>
        )}
        {!isMobile ? (
          <Routes>
            <Route
              path="/services"
              element={
                <div
                  ref={refServices}
                  className={
                    currentPage === "/services"
                      ? style.serviceWrapper
                      : style.serviceWrapperHide
                  }
                >
                  <ServicesBlock
                    setCurrentPage={setCurrentPage}
                    preventServiceRoute={preventServiceRoute}
                    setPreventServiceRoute={setPreventServiceRoute}
                    setPortfolioTransition={setPortfolioTransition}
                  />
                </div>
              }
            />
            <Route
              path="/portfolio"
              element={
                <div
                  ref={scrollRef}
                  className={
                    currentPage === "/portfolio"
                      ? setPortfolioTransitionStyle()
                      : setPortfolioTransitionStyle()
                  }
                >
                  <Main
                    isMainContactUs={isMainContactUs}
                    setIsMainContactUs={setIsMainContactUs}
                    isAppPortfolioProjects={setIsAppPortfolioProjects}
                    setIsAppContactUs={setIsAppContactUs}
                    isCongratulations={isCongratulations}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    portfolioTransition={portfolioTransition}
                    setPortfolioTransition={setPortfolioTransition}
                    portfolioSCroll={portfolioSCroll}
                    setPortfolioSCroll={setPortfolioSCroll}
                    setFriendsTransition={setFriendsTransition}
                    setIsFriendsChange={setIsFriendsChange}
                  />
                </div>
              }
            />
            <Route
              path="/friends"
              element={
                <div
                  className={
                    currentPage === "/friends"
                      ? style.friendsPageWrapper
                      : style.friendsPageWrapperHide
                  }
                >
                  <FriendsPage
                    setIsMainContactUs={setIsMainContactUs}
                    setIsAppContactUs={setIsAppContactUs}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    portfolioTransition={portfolioTransition}
                    setPortfolioTransition={setPortfolioTransition}
                    setFriendsTransition={setFriendsTransition}
                    friendsTransition={friendsTransition}
                    setIsFriendsChange={setIsFriendsChange}
                    setIsContactsChange={setIsContactsChange}
                    isFriendsChange={isFriendsChange}
                    isContactsChange={isContactsChange}
                  />
                </div>
              }
            />
            <Route
              path="/contacts"
              element={
                <div
                  className={
                    currentPage === "/contacts"
                      ? style.footerPageWrapper
                      : style.footerPageWrapperHide
                  }
                >
                  <Footer
                    setIsMainContactUs={setIsMainContactUs}
                    setIsAppContactUs={setIsAppContactUs}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    isContactUs={setIsAppContactUs}
                    setPortfolioTransition={setPortfolioTransition}
                    setFriendsTransition={setFriendsTransition}
                    setIsContactsChange={setIsContactsChange}
                  />
                </div>
              }
            />
          </Routes>
        ) : currentPage ===
          "/" ? null : isAppPortfolioProjects ? null : !isAppContactUs ? null : (
          <div
            className={
              isMobileContent
                ? !isMobileContentFromSolutio
                  ? style.mobileContentWrapperOffAnimation
                  : style.mobileContentWrapper
                : style.mobileContentWrapperHide
            }
            onTouchStart={handleTouchStartMobailScrollDirection}
            onTouchMove={handleTouchMoveMobailScrollDirection}
          >
            <div
              className={
                inView
                  ? style.mobailHeaderOffOpacityBackground
                  : style.mobailHeaderBackground
              }
            >
              {scrollDirection === "down" ? (
                <ContactUsButton
                  className={style.mobailHeaderContactUsButton}
                  onClick={() => {
                    setLastMobileContent(window.scrollY);
                  }}
                />
              ) : null}
            </div>
            <div
              id="services"
              ref={refServices}
              className={
                isMobile
                  ? style.serviceWrapper
                  : currentPage === "/services"
                  ? style.serviceWrapper
                  : style.serviceWrapperHide
              }
            >
              <ServicesBlock
                setCurrentPage={setCurrentPage}
                preventServiceRoute={preventServiceRoute}
                setPreventServiceRoute={setPreventServiceRoute}
                setPortfolioTransition={setPortfolioTransition}
                setIsMobileContent={setIsMobileContent}
                isMobile={isMobile}
              />
            </div>
            <div
              id="portfolio"
              ref={scrollRef}
              className={
                currentPage === "/portfolio"
                  ? setPortfolioTransitionStyle()
                  : setPortfolioTransitionStyle()
              }
            >
              <Main
                isMainContactUs={isMainContactUs}
                setIsMainContactUs={setIsMainContactUs}
                isAppPortfolioProjects={setIsAppPortfolioProjects}
                setIsAppContactUs={setIsAppContactUs}
                isCongratulations={isCongratulations}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                portfolioTransition={portfolioTransition}
                setPortfolioTransition={setPortfolioTransition}
                portfolioSCroll={portfolioSCroll}
                setPortfolioSCroll={setPortfolioSCroll}
                setFriendsTransition={setFriendsTransition}
                setIsFriendsChange={setIsFriendsChange}
                isMobile={isMobile}
              />
            </div>
            <div
              className={
                isMobile
                  ? style.friendsPageWrapper
                  : currentPage === "/friends"
                  ? style.friendsPageWrapper
                  : style.friendsPageWrapperHide
              }
            >
              <FriendsPage
                setIsMainContactUs={setIsMainContactUs}
                setIsAppContactUs={setIsAppContactUs}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                portfolioTransition={portfolioTransition}
                setPortfolioTransition={setPortfolioTransition}
                setFriendsTransition={setFriendsTransition}
                friendsTransition={friendsTransition}
                setIsFriendsChange={setIsFriendsChange}
                setIsContactsChange={setIsContactsChange}
                isFriendsChange={isFriendsChange}
                isContactsChange={isContactsChange}
                isMobile={isMobile}
              />
            </div>
            <div
              id="footerPageWrapper"
              className={
                isMobile
                  ? null
                  : currentPage === "/contacts"
                  ? style.footerPageWrapper
                  : style.footerPageWrapperHide
              }
              ref={refFriendsPage}
            >
              <Footer
                setIsMainContactUs={setIsMainContactUs}
                setIsAppContactUs={setIsAppContactUs}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                isContactUs={setIsAppContactUs}
                setPortfolioTransition={setPortfolioTransition}
                setFriendsTransition={setFriendsTransition}
                setIsContactsChange={setIsContactsChange}
                isMobile={isMobile}
              />
            </div>
          </div>
        )}
        <Routes>
          <Route
            path="/portfolioProjects"
            element={
              <PortfolioProjects
                // isPortfolioProjects={setIsPortfolioProjects}
                isAppPortfolioProjects={setIsAppPortfolioProjects}
                scrollDirection={scrollDirection}
                setScrollDirection={setScrollDirection}
                handleTouchStartMobailScrollDirection={
                  handleTouchStartMobailScrollDirection
                }
                handleTouchMoveMobailScrollDirection={
                  handleTouchMoveMobailScrollDirection
                }
                isMobile={isMobile}
                setIsMobileContentFromSolutio={setIsMobileContentFromSolutio}
                setIsFromPortfolioProjectsToContactUs={
                  setIsFromPortfolioProjectsToContactUs
                }
              />
            }
          />
          <Route
            path="/contactUs"
            element={
              <ContactUs
                setIsMainContactUs={setIsMainContactUs}
                setIsAppContactUs={setIsAppContactUs}
                currentPage={currentPage}
                setIsFirstTime={setIsFirstTime}
                isMobile={isMobile}
                setIsMobileContentFromSolutio={setIsMobileContentFromSolutio}
                setIsFromContactUs={setIsFromContactUs}
                setIsAppPortfolioProjects={setIsAppPortfolioProjects}
                isFromPortfolioProjectsToContactUs={
                  isFromPortfolioProjectsToContactUs
                }
                setIsFromPortfolioProjectsToContactUs={
                  setIsFromPortfolioProjectsToContactUs
                }
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default App;
