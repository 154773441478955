import React from "react";
import style from "./TitlePage.module.scss";

const TitlePage = ({ ...props }) => {
  return (
    <section className={style.TitlePage} {...props}>
      <div className={style.TitlePageDescriptioncWrapper}>
        <h1 className={style.TitlePageTitle}>Solutio</h1>
        <p className={style.TitlePageText}>
          Full cycle product creation studio. We create digital solution.
        </p>
        <div className={style.TitlePageCase}>
          <h2 className={style.TitlePageSubtitle}>studio</h2>
        </div>
      </div>
    </section>
  );
};

export default TitlePage;
