import React, { useRef } from "react";
import style from "./Portfolio.module.scss";
import PortfolioProjectButton from "../../UI/PortfolioProsectButton/PortfolioProjectButton";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { ReactComponent as PortfolioProject1 } from "../../../assets/PortfolioProjects1.svg";
import PortfolioBackground from "../../../assets/portfolioBackground1.png";
import ContactUsButton from "../../UI/ContactUsButton/ContactUsButton";
const Portfolio = React.forwardRef(
  ({
    setIsAppContactUs,
    setCurrentPage,
    portfolioTransition,
    setPortfolioTransition,
    portfolioSCroll,
    setPortfolioSCroll,
    setFriendsTransition,
    setIsFriendsChange,
    isMobile,
  }) => {
    const [isPortfolioProject, setIsPortfolioProject] = useState();

    // useEffect(() => {
    //   setPortfolioSCroll(window.scrollY);
    //   console.log(portfolioSCroll);
    // }, []);

    const portfolioRef = useRef();

    const navigate = useNavigate();
    useEffect(() => {
      if (!isMobile) {
        if (portfolioTransition === "fromServices") {
          window.scrollTo(0, window.scrollY + 1);
        }
      }
    }, []);

    useEffect(() => {
      if (!isMobile) {
        if (portfolioTransition === "fromContact") {
          if (portfolioRef.current) {
            window.scrollTo(0, portfolioSCroll - 1);
          }
        }
      }
    }, []);
    let windowScroll = 0;

    const pointControl = () => {
      windowScroll = window.scrollY;
      if (windowScroll >= 3400 + (window.innerHeight - 66)) {
        setIsPortfolioProject((prev) => (prev = "Work1"));
      }
      if (windowScroll >= 3700 + (window.innerHeight - 66)) {
        setIsPortfolioProject((prev) => (prev = "Work2"));
      }
      if (windowScroll >= 4000 + (window.innerHeight - 66)) {
        setIsPortfolioProject((prev) => (prev = "Work3"));
      }
      if (windowScroll >= 4300 + (window.innerHeight - 66)) {
        setIsPortfolioProject((prev) => (prev = "Work4"));
      }
    };

    useEffect(() => {
      window.addEventListener("scroll", pointControl);
      return () => {
        window.removeEventListener("scroll", pointControl);
      };
    }, []);

    const onMouseWheelDown = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight
      ) {
        setPortfolioTransition("toContact");
        setFriendsTransition("fromPortfolio");
        setCurrentPage("/friends");
        setPortfolioSCroll(window.scrollY);
        setIsFriendsChange(true);
      }
    };

    const onMouseWheelUp = () => {
      setPortfolioTransition("toServices");
      setCurrentPage("/services");
    };

    const onMouseWheel = (e) => {
      window.scrollY > 0 ? onMouseWheelDown() : onMouseWheelUp();
    };

    const [scrollDirection, setScrollDirection] = useState("");

    let touchStartY = 0;
    let touchEndY = 0;
    const handleTouchStart = (event) => {
      touchStartY = event.touches[0]?.clientY;
      console.log("STARTY" + touchStartY);
    };
    const handleTouchMove = (event) => {
      touchEndY = event.touches[0].clientY;
    };

    const handleTouchEnd = (event) => {
      if ((touchEndY = touchStartY)) {
        setScrollDirection("down");
        onMouseWheel();
      } else if (touchEndY < touchStartY) {
        setScrollDirection("up");
        onMouseWheel();
      } else {
        setScrollDirection("none");
      }
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight
      ) {
        onMouseWheelDown();
      }
    };

    return (
      <section
        className={style.portfolio}
        id="Cases"
        onWheel={onMouseWheel}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        ref={portfolioRef}
      >
        <img
          src={PortfolioBackground}
          alt=""
          className={style.portfolioBackground}
        />

        <div className={style.portfolioContentWrapper}>
          <div className={style.portfolioHeader}>
            <h2 className={style.portfolioHeaderTitle}> our cases </h2>
            <p className={style.portfolioHeaderSubTitle}>Selected works</p>
          </div>
          <div className={style.portfolioWorks}>
            <div
              className={` ${
                isMobile
                  ? isPortfolioProject === "Work1"
                    ? style.portfolioWorksProjectActive
                    : style.portfolioWorksMobileProjectActive
                  : style.portfolioWorksProject
              } ${style.portfolioWorksProject1}`}
              onClick={() => {
                navigate("/portfolioProjects?project=project1");
              }}
            >
              <PortfolioProjectButton
                text="Financial SAAS platform"
                className={
                  isMobile
                    ? isPortfolioProject === "Work1"
                      ? style.portfolioWorksProjectButtonActive
                      : style.portfolioWorksProjectButton
                    : style.portfolioWorksProjectButton
                }
              />
            </div>
            <div
              className={`${
                isMobile
                  ? isPortfolioProject === "Work2"
                    ? style.portfolioWorksProjectActive
                    : style.portfolioWorksMobileProjectActive
                  : style.portfolioWorksProject
              } ${style.portfolioWorksProject2}`}
              onClick={() => {
                navigate("/portfolioProjects?project=project2");
              }}
            >
              <PortfolioProjectButton
                text="UI/UX design for AI Product Creators"
                className={
                  isMobile
                    ? isPortfolioProject === "Work2"
                      ? style.portfolioWorksProjectButtonActive
                      : style.portfolioWorksProjectButton
                    : style.portfolioWorksProjectButton
                }
              />
            </div>
            <div
              className={`${
                isMobile
                  ? isPortfolioProject === "Work3"
                    ? style.portfolioWorksProjectActive
                    : style.portfolioWorksMobileProjectActive
                  : style.portfolioWorksProject
              } ${style.portfolioWorksProject3}`}
              onClick={() => {
                navigate("/portfolioProjects?project=project3");
              }}
            >
              <PortfolioProjectButton
                text="Eco Application"
                className={
                  isMobile
                    ? isPortfolioProject === "Work3"
                      ? style.portfolioWorksProjectButtonActive
                      : style.portfolioWorksProjectButton
                    : style.portfolioWorksProjectButton
                }
              />
            </div>
            <div
              className={`${
                isMobile
                  ? isPortfolioProject === "Work4"
                    ? style.portfolioWorksProjectActive
                    : style.portfolioWorksMobileProjectActive
                  : style.portfolioWorksProject
              } ${style.portfolioWorksProject4}`}
              onClick={() => {
                navigate("/portfolioProjects?project=project4");
              }}
            >
              <PortfolioProjectButton
                text="Healthcare SAAS platform"
                className={
                  isMobile
                    ? isPortfolioProject === "Work4"
                      ? style.portfolioWorksProjectButtonActive
                      : style.portfolioWorksProjectButton
                    : style.portfolioWorksProjectButton
                }
              />
            </div>
          </div>
          <div className={style.portfolioFooter}>
            <p className={style.portfolioFooterText}>Want to see more?</p>
            <ContactUsButton
              className={style.portfolioFooterButton}
              onClick={() => {
                navigate("/contactUs");
                setIsAppContactUs(false);
              }}
            />
          </div>
        </div>
      </section>
    );
  }
);

export default Portfolio;
