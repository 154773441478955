import React from "react";
import { useNavigate } from "react-router-dom";

const ContactUsButton = ({ ...props }) => {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      {...props}
      onClick={() => {
        navigate("/contactUs");
      }}
    >
      Contact Us
    </button>
  );
};

export default ContactUsButton;
