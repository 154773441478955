import React from "react";
import style from "./ContactInput.module.scss";

const ContactInput = ({ ...props }) => {
  return (
    <input
      type="text"
      className={style.input}
      {...props}
      placeholder={props.placeholder}
    />
  );
};

export default ContactInput;
